import React from 'react';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import _ from 'lodash';

const AppHelmet = (props) => {
  const { page_config } = props;
  let title = _.get(page_config, "common.title_app");
  let fav_icon =  _.get(page_config, "common.fav_icon_app");
  let FAV_URL = `${process.env.REACT_APP_CDN_URL}${fav_icon}`
  return (
    <Helmet>
      <link rel="shortcut icon" href={FAV_URL}  sizes="32x32"/>
      <title>{title}</title>
    </Helmet>
  )
}

const mapStateToProps = (state) => {
  return {
    page_config: _.get(state, "my_profile.page_config", {})
  }
}

export default connect(mapStateToProps)(AppHelmet)