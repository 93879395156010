import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"; // Assuming you're using Reactstrap for styling
const StaySignInModal = ({
  isOpen,
  toggleModal,
  handleStaySignIn,
  formatTime,
  idleTimeout,
  idleTimerCounter,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggleModal} centered>
      <ModalHeader className="border-0 mb-0" toggle={toggleModal}>
        <h5 className="font-weight-bold">Confirmation</h5>
      </ModalHeader>
      <ModalBody className="mt-0 pb-0">
        You have been inactive for {formatTime(idleTimeout)}. You will be
        automatically signed out in {formatTime(idleTimerCounter)}.. To stay
        signed in, click on "Stay Sign In".
      </ModalBody>
      <ModalFooter className="border-0">
        <Button color="primary" onClick={handleStaySignIn}>
          Stay Signed In
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default StaySignInModal;
