import React, { Fragment, useState } from "react";
import _ from 'lodash';
import { Collapse, Nav, NavItem, NavLink } from "reactstrap";
import { useHistory } from "react-router-dom";
import allPaths from "../../../route/allPaths";
import './style.css';

let lists = [
  {
    footer_title: 'Home',
    icon: 'home.svg'
  },
  {
    footer_title: 'Solutions',
    icon: 'solution.svg',
    event_name: 'solutions'
  },
  {
    footer_title: 'Products',
    icon: 'product.svg',
    event_name: 'products'
  },
  {
    footer_title: 'Sign Up',
    icon: 'sign-up.svg',
    event_name: 'signUp'
  },
  {
    footer_title: 'More',
    icon: 'more.svg',
    event_name: 'others'
  },
]

let solutions_arr = [
  {
    title: 'For Enterprise',
    details: [
      {
        label: 'Financial Institutions',
        url: 'https://about.crediwatch.com/solutions/enterprise/financial-institutions/',
      },
      {
        label: 'Embedded Finance',
        url: 'https://about.crediwatch.com/solutions/enterprise/embedded-finance-api/',
      },
      {
        label: 'Professional Services',
        url: 'https://about.crediwatch.com/solutions/enterprise/professional-firms/',
      }
    ]
  },
  {
    title: 'For Businesses',
    details: [
      {
        label: 'Small businesss',
        url: 'https://about.crediwatch.com/solutions/business/msme/',
      },
      {
        label: 'Verify Covid Suppliers',
        url: 'https://about.crediwatch.com/solutions/business/verify-covid-resources/',
      },
      {
        label: 'Law Firms',
        url: 'https://about.crediwatch.com/solutions/business/law-firms/',
      }
    ]
  },
]

// let contact_arr = [
//   {
//     title: 'Get in touch',
//     details: [
//       {
//         label: 'Request demo',
//         url: 'https://about.crediwatch.com/contact/request-a-demo/',
//       },
//       {
//         label: 'Contact us',
//         url: 'https://about.crediwatch.com/contact/contact-us/',
//       }
//     ]
//   },
// ]

let product_arr = [
  {
    title: 'Products',
    details: [
      {
        label: 'Early Warning Systems',
        url: 'https://about.crediwatch.com/product/early-warning-systems/',
      },
      {
        label: 'Enhanced Credit Reports',
        url: 'https://about.crediwatch.com/product/enhanced-credit-reports/',
      },
      {
        label: 'Fin Tracker',
        url: 'https://about.crediwatch.com/product/fintracker/',
      },
      {
        label: 'UBO Analysis',
        url: 'https://about.crediwatch.com/product/ubo-analysis/',
      },
      {
        label: 'Trust Score',
        url: 'https://about.crediwatch.com/product/trustscore/',
      },
      {
        label: 'Quick Reports',
        url: 'https://about.crediwatch.com/product/quick-reports/',
      },
      {
        label: 'Case Management',
        url: 'https://about.crediwatch.com/product/early-warning-systems/case-management/',
      }
    ]
  }
]

let other_arr = [
  {
    title: 'More',
    details: [
      {
        label: 'About Crediwatch',
        url: 'https://about.crediwatch.com/about/about-us/',
      },
      {
        label: 'News',
        url: 'https://about.crediwatch.com/about/news/',
      },
      {
        label: 'Resources',
        url: 'https://about.crediwatch.com/resources/',
      },
      {
        label: 'Careers',
        url: 'https://about.crediwatch.com/about/careers/',
      },
      {
        label: 'Become a partner',
        url: 'https://about.crediwatch.com/about/channel-partner/',
      }
    ]
  },
]

const FixedMobile = () => {
  const history = useHistory();
  const [open_drawer, setDrawer] = useState('');
  const get_header_cdn_path = (file_name) => {
    return `${process.env.REACT_APP_CDN_URL}/assets/icons/header/${file_name}`
  }

  const getInfoUI = (data, is_bold) => {
    let ui_arr = [];

    if (data && _.isArray(data)) {
      data.forEach((a) => {
        ui_arr.push(
          <NavItem>
            <NavLink href={`${a.url}`} >
              <span className={`${is_bold && 'font-weight-bold'} ff-renner-book`}>{a.title || a.label}</span>
            </NavLink>
            {a.details && (
              <div className="pl-3">
                {getInfoUI(a.details)}
              </div>)}
          </NavItem>
        )
      })
    }
    return (
      <Nav vertical>
        {ui_arr}
      </Nav>
    )
  }

  const getCollapseUI = (data) => {
    return (
      <Collapse isOpen={true}>
        <div className="border footer-background-color">
          <div className="bg-white rounded ">
            <div
              className="text-right p-2"
              onClick={() => setDrawer('')}
            >
              <i className="fa fa-times"> </i>
            </div>
            {getInfoUI(data, true)}
          </div>
        </div>
      </Collapse>
    )
  }

  const icon_click_event = (event_name) => {
    if (event_name) {
      if (event_name === 'signUp') {
        window.open("https://account.crediwatch.com/signup", "_self");
      }
      else if(event_name === 'home'){
       history && history.push(allPaths.PUBLIC_HOME_ROUTE);
      }
     
     else {
        setDrawer(event_name)
      }
    }
  }

  return (
    <Fragment>
      {open_drawer === 'solutions' && getCollapseUI(solutions_arr)}
      {open_drawer === 'products' && getCollapseUI(product_arr)}
      {open_drawer === 'others' && getCollapseUI(other_arr)}
      <div className="mobile-footer d-lg-none fixed-bottom bg-white" >
        <div className="d-flex justify-content-center" style={{ padding: '4px' }}>
          {lists.map((a, index) => (
            <div className="m-auto cursor-pointer d-flex flex-column justified-content-center align-items-center" key={index} onClick={() => icon_click_event(a.event_name)}>
              <img src={get_header_cdn_path(a.icon)} className="icon-svg" height={"32px"} />
              <div style={{ fontSize: '8px', lineHeight: '8px' }}>{a.footer_title}</div>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  )
}

export default FixedMobile;
