import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";

import { Collapse, Button } from "reactstrap";
import FeatureList from "../AppHeader/Components/tabComponents/FeatureList";
import LegalDrawer from "./components/LegalDrawer";
import OrderReportFromDashboard from "../../pages/Home/dashboard/components/order_report_dashboard";
import { IoIosMegaphone } from "react-icons/io";

const CwFooter = (props) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [featureShow, setFeatureShow] = useState(false);
  const [ellipseShow, setEllipseShow] = useState(false);
  const [orderShow, setOrderShow] = useState(false);
  const [activeButton, setActiveButton] = useState("home");
  const popOverId = "PopoverMobileVersion";

  // const handleSearch = () => {
  //   setShow(!show);
  //   props.showSearchField(show);
  // };

  // const handleFeature = () => {
  //   setIsOpen(!isOpen);
  // };

  const homeRoute = () => {
    props.history.push("/");
  };

  // const productsRoute = () => {
  //   props.history.push(allPaths.PRODUCTS_ROUTE);
  // };

  // const toggle = () => {
  //   setPopoverOpen(!popoverOpen);
  // };

  const openCollapse = () => {
    setDrawerOpen(!drawerOpen);
    setFeatureShow(false);
    setEllipseShow(false);
  };

  const getFabUI = () => {
    const { fabItems } = props;
    if (fabItems && fabItems.component && fabItems.action) {
      const { component: Comp, action, params } = fabItems;
      return (
        <>
          <div
            className={"ui-theme-settings"}
            onClick={() => openCollapse(drawerOpen)}
            aria-controls="example-collapse-text"
            aria-expanded={drawerOpen}
          >
            <Button
              className="btn-open-options"
              id="TooltipDemo"
              color="primary"
            >
              <Comp action={action} params={params} />
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={"ui-theme-settings"}>
            <Button
              className="btn-open-options"
              id="TooltipDemo"
              color="primary"
              onClick={() => setOrderShow(!orderShow)}
            >
              <i className="fal fa-plus"></i>
            </Button>
          </div>
        </>
      );
    }
  };

  const updateActiveStateFunc = (activeButton) => {
    setActiveButton(activeButton);
  };

  const { openInputbox, openInput, page_config } = props;
  const show_search_bar = _.get(page_config, "header.show_search_bar", false);
  const show_feature_icon = _.get(
    page_config,
    "header.show_feature_icon",
    false
  );
  const footer_text = _.get(page_config, "footer.text", false);
  let copyright_year = new Date().getFullYear();

  return (
    <>
      <div className="w-100 bg-white border-1 border-light d-flex justify-content-between align-items-center">
        <div className="p-3 d-none d-sm-none d-md-none d-lg-block show">
          <small>©{copyright_year} {footer_text}</small>
        </div>
      </div>
      <div className="d-lg-none fixed-bottom bg-white  ">
        {getFabUI()}
        <Collapse isOpen={featureShow}>
          <div className="border footer-background-color">
            <div className="bg-white p-3 rounded ">
              <div
                className="text-right h5"
                onClick={() => setFeatureShow(!featureShow)}
              >
                <i className="fa fa-times"> </i>
              </div>
              <FeatureList />
            </div>
          </div>
        </Collapse>

        <Collapse isOpen={ellipseShow}>
          <div className="border footer-background-color">
            <div className="bg-white rounded ">
              <div
                className="text-right h5 p-2"
                onClick={() => setEllipseShow(!ellipseShow)}
              >
                <i className="fa fa-times"> </i>
              </div>
              <LegalDrawer />
            </div>
          </div>
        </Collapse>

        <Collapse isOpen={drawerOpen}>
          <div
            className="border footer-background-color"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <div className="bg-white rounded ">
              <div
                className="text-right h5 p-2"
                onClick={() => setDrawerOpen(!drawerOpen)}
              >
                <i className="fa fa-times"> </i>
              </div>

              <div className="text-center">
                <IoIosMegaphone fontSize="60px" />
                <div className="mt-2">Coming Soon</div>
              </div>
            </div>
          </div>
        </Collapse>

        <Collapse isOpen={orderShow}>
          <div className="border footer-background-color order-report-mobile-screen">
            <div className="">
              <div
                className="text-right h5 p-2"
                onClick={() => setOrderShow(!orderShow)}
              >
                <i className="fa fa-times"> </i>
              </div>
              <div>
                <OrderReportFromDashboard mobileView={orderShow} />
              </div>
            </div>
          </div>
        </Collapse>

        <div className="d-flex justify-content-around h4 mb-0  pt-2 pb-2">
          <div
            className={`m-auto cursor-pointer d-flex flex-column justified-content-center align-items-center p-1 ${
              activeButton === "home" ? "bg-light" : ""
            }`}
            onClick={() => {
              updateActiveStateFunc("home");
              homeRoute();
            }}
          >
            <img
              src={`${process.env.REACT_APP_CDN_URL}/assets/icons/header/home.svg`}
              className="icon-svg"
              height={"20px"}
            />
            <div style={{ lineHeight: "8px" }} className="fs-12 mt-1">
              Home
            </div>
          </div>
          <div
            className={`m-auto cursor-pointer d-flex flex-column justified-content-center align-items-center p-1 ${
              activeButton === "alert" ? "bg-light" : ""
            }`}
            onClick={() => {
              setFeatureShow(false);
              setDrawerOpen(!drawerOpen);
              setEllipseShow(false);
              updateActiveStateFunc("alert");
            }}
          >
            <i className="fa-sharp fa-regular fa-bell fs-16 text-primary"></i>
            <div style={{ lineHeight: "8px" }} className="fs-12 mt-2">
              Coming Soon
            </div>
          </div>
          {show_search_bar && (
            <div
              className={`m-auto cursor-pointer d-flex flex-column justified-content-center align-items-center p-1 ${
                activeButton === "search" ? "bg-light" : ""
              }`}
              data-tut="dashboard-mobile-searchbar"
              onClick={() => {
                openInputbox();
                updateActiveStateFunc("search");
              }}
            >
              <i
                className={`${
                  openInput
                    ? "fa fa-thin fa-xmark fs-16 text-primary"
                    : "fa fa-light fa-magnifying-glass fs-16 text-primary"
                }`}
              ></i>
              <div style={{ lineHeight: "8px" }} className="fs-12 mt-2">
                {`${openInput ? "Close" : "Search"}`}
              </div>
            </div>
          )}
          {show_feature_icon && (
            <div
              className="m-auto cursor-pointer"
              onClick={() => {
                setFeatureShow(!featureShow);
                setDrawerOpen(false);
                setEllipseShow(false);
                updateActiveStateFunc("feature");
              }}
            >
              <div
                className={`m-auto cursor-pointer d-flex flex-column justified-content-center align-items-center p-1 ${
                  activeButton === "feature" ? "bg-light" : ""
                }`}
              >
                <i className="fa fa-thin fa-border-all fs-16 text-primary"></i>
                <div style={{ lineHeight: "8px" }} className="fs-12 mt-2">
                  Features
                </div>
              </div>
              <div></div>
            </div>
          )}
          <div
            className="m-auto cursor-pointer"
            id={popOverId}
            onClick={() => {
              setEllipseShow(!ellipseShow);
              setFeatureShow(false);
              setDrawerOpen(false);
              updateActiveStateFunc("more");
            }}
          >
            <div
              className={`m-auto cursor-pointer d-flex flex-column justified-content-center align-items-center p-1 ${
                activeButton === "more" ? "bg-light" : ""
              }`}
            >
              <i
                className="fa fa-ellipsis border rounded-circle  border-primary fs-16 p-1 text-primary"
                height={"20px"}
                style={{ lineHeight: "8px" }}
              ></i>
              <div style={{ lineHeight: "8px" }} className="fs-12 mt-2">
                More
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    fabItems: state.ThemeOptions.fabItems,
    colorScheme: state.ThemeOptions.colorScheme,
    page_config: _.get(state, "my_profile.page_config", {}),
  };
};

export default connect(mapStateToProps)(withRouter(CwFooter));
