import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import allPaths from './allPaths';
import service from '../utils/service';

const BasicSetupHandler = (props) => {
  let default_obj = {
    api_status: '',
    complete_status: ''
  }
  const history = useHistory();

  const [info, setInfo] = useState(default_obj)

  let get_api_status = _.get(info, "api_status");
  let get_complete_status = _.get(info, "complete_status");
  const basic_setup_value = process.env.REACT_APP_BASIC_SETUP;

  useEffect(() => {
    available_info_API_call()
    return () => {}
  }, [])

  const available_info_API_call = async () => {
    setInfo({
      api_status: '',
      complete_status: ''
    })
    let info_API_URL = `/v1/user/steps/1`;
    let res = await service('get', info_API_URL);
    let status = _.get(res, "data.step_status");
    if (status === 'done') {
      setInfo({
        api_status: 'done',
        complete_status: 'done'
      })
    } else {
      setInfo({
        api_status: 'done',
        complete_status: 'not-done'
      })
    }
  }

  useEffect(() => {
    if (basic_setup_value === 'rupee_boss' && get_api_status === 'done') {
      if (get_complete_status === 'done') {
        history.push(allPaths.CONSENT_ROUTE)
      } else {
        history.push(allPaths.RUBEE_BOSS_SETUP_ROUTE)
      }
    }
    return () => {}
  }, [basic_setup_value, get_api_status, get_complete_status])

  return <></>
}


export default BasicSetupHandler;