import _ from 'lodash';

const obj_to_string = (obj, multi_value_obj) => {
  let keys = _.isObject(obj) ? Object.keys(obj) : [];

  let str_keys = ''
  if (keys && _.isArray(keys)) {
    keys.forEach((k) => {
      str_keys += `${k}=${obj[k]}&`;
    })
  }

  let multi_obj_keys =  _.isObject(multi_value_obj) ? Object.keys(multi_value_obj) : [];
  if(multi_obj_keys && _.isArray(multi_obj_keys)) {
    multi_obj_keys.forEach((k) => {
      if(_.isArray(multi_value_obj[k])) {
        multi_value_obj[k].forEach((v) => {
          str_keys += `${k}=${v}&`;
        })
      } else {
        str_keys += `${k}=${multi_value_obj[k]}&`;
      }
    })
  }
  return str_keys;
}

export default obj_to_string