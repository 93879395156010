import React, { Fragment } from 'react';
import HubSpot from "../../../components/Hubspot";
import FixedMobile from './FixedMobile';

const PublicFooter = () => {

  let ICON_ROOT_PATH = `${process.env.REACT_APP_CDN_URL}/assets/icons/social_media`;
  let social_share_data = [
    {
      icon: `${ICON_ROOT_PATH}/linkedin.svg`,
      target: '_blank',
      url: `https://www.linkedin.com/company/crediwatch/`,
    },
    {
      icon: `${ICON_ROOT_PATH}/twitter.svg`,
      target: '_blank',
      url: `https://twitter.com/crediwatch`,
    },
    {
      icon: `${ICON_ROOT_PATH}/facebook.svg`,
      target: '_blank',
      url: `https://www.facebook.com/crediwatch/`,
    },
    {
      icon: `${ICON_ROOT_PATH}/instagram.svg`,
      target: '_blank',
      url: `https://www.instagram.com/crediwatch/`,
    },
    {
      icon: `${ICON_ROOT_PATH}/youtube.svg`,
      target: '_blank',
      url: `https://www.youtube.com/channel/UCy6E5Xt0pzrH4NxMFEjwXVg`,
    },
  ];

  let cw_about_arr = [
    {
      label: 'Careers',
      url: `https://about.crediwatch.com/about/careers/`,
    },
    {
      label: 'About', 
      url: `https://about.crediwatch.com/about/about-us/`,
    },
    {
      label: 'Contact',
      url: `https://about.crediwatch.com/contact/contact-us/`,
    },
    {
      label: 'Blogs',
      url: `https://about.crediwatch.com/resources/blogs/`,
    },
  ];

  let product_arr = [
    {
      label: 'Early Warning System',
      url: `https://about.crediwatch.com/product/early-warning-systems/`,
    },
    {
      label: 'Enchanced Credit Reports',
      url: `https://about.crediwatch.com/product/enhanced-credit-reports/`,
    },
    {
      label: 'Fin Tracker',
      url: `https://about.crediwatch.com/product/fintracker/`,
    },
    {
      label: 'Trust Score',
      url: `https://about.crediwatch.com/product/trustscore/`,
    },
    {
      label: 'Case Management',
      target: '_blank',
      url: `https://about.crediwatch.com/product/early-warning-systems/case-management/`,
    },
  ];

  let legal_json = [
    {
      label: "Privacy policy",
      url: `https://about.crediwatch.com/legal/privacy-policy/`
    },
    {
      label: "Cookie Policy",
      url: `https://about.crediwatch.com/legal/cookie-policy/`
    },
    {
      label: "API Terms of Use",
      url: `https://about.crediwatch.com/legal/terms-and-conditions/`
    },

  ]

  let copyright_year = new Date().getFullYear();

  let footer_logo = `${process.env.REACT_APP_CDN_URL}/assets/icons/product/footer_logo.svg`;

  let subscribe_svg = `${process.env.REACT_APP_CDN_URL}/assets/icons/product/subscribe.svg`;

  const get_products_ui = () => {
    return (
      <div className='col-md-4 col-6 mb-3'>
        <div className='fs-18 mb-3'>Products</div>
        {product_arr.map((item, index) => (
          <div className='mb-1' key={index}>
            <a href={item.url} className='text-decoration-none text-white'>
              {item.label}
            </a>
          </div>
        ))}

      </div>
    )
  }

  const get_content_ui = () => {
    return (
      <div className='col-md-4 col-6 mb-3'>
        <div className='fs-18 mb-3'>Contents</div>
        {cw_about_arr.map((item, index) => (
          <div className='mb-1' key={index}>
            <a href={item.url}  className='text-decoration-none text-white'>
              {item.label}
            </a>
          </div>
        ))}
      </div>
    )
  }

  const get_legal_ui = () => {
    return (
      <div className='col-md-4 col-6 mb-3'>
        <div className='fs-18 mb-3'>Legal</div>
        {legal_json.map((item, index) => (
          <div className="mb-1" key={index}>
            <a href={item.url} target={item.target} className="text-white">
              {item.label}
            </a>
          </div>
        ))}
      </div>
    )
  }

  const get_left_ui = () => {
    return (
      <div className="col-12 col-lg-7">
        <div className='row'>
          <div className="col-12">
            <div className='mb-5'><img src={footer_logo} alt="crediwatch_logo" /></div>
          </div>
        </div>
        <div className='row'>
          {get_products_ui()}
          {get_content_ui()}
          {get_legal_ui()}
        </div>
      </div>
    )
  }

  const get_right_form_ui = () => {
    return (
      <div className='col-lg-5 col-12'>
        <div className='card mb-3'>
          <div className='card-body'>
            <div className='text-primary mb-3'>
              <img src={subscribe_svg} alt='subscribe' width="48px" />
            </div>
            <div>
              <div className='text-primary fs-22'>Join the newsletter and explore
                our new product updates</div>
              <div className='text-secondary fs-14 pt-2'>
                Subscribe to our newsletter to receive the latest news, product updates and exclusive offers.
              </div>
              <div className='newsletter mt-3 mb-3'>
                <div>
                  <HubSpot
                    region="na1"
                    portalId="20285543"
                    formId="31a78d84-b863-4537-a6a7-d17c5d045965"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const get_copyrigts_ui = () => {
    return (
      <div className='row'>
        <div className='d-xs-block text-center'>
          <div className='d-md-flex justify-content-between flex-md-row-reverse custom-footer-bottom' >
            <div className='m-3 d-flex justify-content-center'>
              {social_share_data.map((item, index) => (
                <div className='ml-2 mr-2' key={index}>
                  <a className='text-decoration-none' target={item.target} href={item.url}>
                    <img src={item.icon} />
                  </a>
                </div>
              ))}
            </div>
            <div className="m-3 d-flex justify-content-center p-0">
              {`©${copyright_year} | All Rights Reserved.`}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      <div className="bg-primary text-white p-3 pt-5 pr-lg-5 pl-lg-5">
        <div className='row'>
          {get_left_ui()}
          {get_right_form_ui()}
        </div>
        <hr />
        {get_copyrigts_ui()}
      </div>
      <FixedMobile />
    </Fragment>
  )
}

export default PublicFooter;
