import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import _ from 'lodash';
import Loader from 'react-loaders';

import { setSideBarItems } from "../reducers/ThemeOptions";
import { loadPartyInfo } from '../actions/party/action';
import EqaroHandler from './EqaroHandler';
import BasicSetupHandler from './BasicSetupHandler';
import allPaths from './allPaths';
import { getToken, addRedirectURL } from '../utils/getToken';
import { getMyprofile } from "../actions/myprofile/action";
import { Mixpanel } from '../utils/mixpanel';
import redirectLoginSSO from '../utils/redirectLoginSSO';

const LoadingComponent = () => {
  return (
    <div className="loader-container">
      <div className="loader-container-inner">
        <div className="text-center">
          <Loader type="ball-pulse-rise" />
        </div>
        <h6 className="mt-5">
          Crediwatch Risk intelligence decisioning system
          <small>
            Please wait while Crediwatch prepares a great experience for you
          </small>
        </h6>
      </div>
    </div>
  )
}

const ErrorMyProfile = () => {
  useEffect(() => {
    Mixpanel.track("error_occurred", { error_type: "Error On Profile" });
  }, []);

  return (
    <div>Error On Profile</div>
  )
}

const PrivateRoute = (props) => {
  const token = getToken();
  const { component: Component, path, profile_status, profile_instance, eqaro_step_info, ...rest } = props;

  useEffect(() => {
    const { getMyprofile } = props;
    if (!token || token === '') {
      addRedirectURL()
      redirectLoginSSO()
    } else {
      getMyprofile();
    }
    return () => {}
  }, []);

  const setDefaultFabAndSideBar = () => {
    const { setSideBarItems, loadPartyInfo } = props;
    setSideBarItems([]);
    loadPartyInfo()
  };

  useEffect(() => {
    setDefaultFabAndSideBar()
  }, [path])

 
  return (
    <>
      <Route
        path={path}
        {...rest}
        render={(prop) =>
          (!token || token === '' || profile_status === 'loading') ?
            <LoadingComponent /> :
            profile_status === 'done' ?
              <>
                <Component />
                <EqaroHandler path={path} />
                <BasicSetupHandler path={path} />
              </>
              :
              profile_status === 'error' ?
                <ErrorMyProfile />
                :
                <LoadingComponent />
        }
      />
    </>
  )

}

function mapStateToProps(state) {
  return {
    profile_status: _.get(state, "my_profile.profile_status")
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMyprofile,
      setSideBarItems,
      loadPartyInfo
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)