import React,{useEffect,useState} from "react";
import service from "../../utils/service";
import _ from 'lodash';

const ErrorPanel = () => {

  const [showMsg, setShowMsg] = useState(false);

    useEffect( () => {
        getBillingDetails()
    },[])

    const getBillingDetails = async () => {
        const API_URL = '/v1/account/myorg'
        let res = await service('get', API_URL,{});
        if (res && res.status === 200) {
          const billing = _.get(res, "response.data.billing_status", "");
          if (billing === "overdue") {
            setShowMsg(true);
          }
          setShowMsg(true);
        }
    }

    return showMsg ? (
      <div className="panel text-white bg-danger">
        <div className="panel-heading">
          You have one or more invoice payments due. please contact the
          administrator to address the issue and avoid suspension of the accounts
        </div>
      </div>
    ) : (
      <></>
  );
};

export default ErrorPanel;
