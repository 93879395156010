import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

const GA =  (props) => {
  let ga_code = process.env.REACT_APP_GOOGLE_ANALYTICS;
  let location = _.get(props, "history.location")

  useEffect(() => {
    if(ga_code) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
    }
  }, []);

  useEffect(() => {
    if(location) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [location])

  return (
    <></>
  )
}

export default withRouter(GA);
