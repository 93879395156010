import * as actionType from "../../actions/dashboard/actionTypes";

let initialState = {
  product: {
    status: "not-triggered",
    result: null,
  },
  watchlist_parties: {
    status: "not-triggered",
    result: null,
  },
  is_Watchlisted: {
    status: "not-triggered",
    result: null,
  },
  userData: {
    status: "not-triggered",
    result: null,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionType.LOAD_PRODUCT_DATA:
      return {
        ...state,
        product: {
          status: "loading",
          result: null,
        },
      };
    case actionType.SET_PRODUCT_DATA:
      return {
        ...state,
        product: {
          status: "done",
          result: action.payload,
        },
      };
    case actionType.LOAD_WATCHLIST_PARTIES:
      return {
        ...state,
        watchlist_parties: {
          status: "loading",
          result: null,
        },
      };
    case actionType.SET_WATCHLIST_PARTIES:
      return {
        ...state,
        watchlist_parties: {
          status: "done",
          result: action.payload,
        },
      };
    case actionType.LOAD_IS_WATCHLISTED_PARTY:
      return {
        ...state,
        is_Watchlisted: {
          status: "loading",
          result: null,
        },
      };
    case actionType.SET_IS_WATCHLISTED_PARTY:
      return {
        ...state,
        is_Watchlisted: {
          status: "done",
          result: action.payload,
        },
      };
    default:
  }
  return state;
}
