
import { Nav, NavItem, NavLink } from 'reactstrap'
import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash';

function LegalDrawer(props) {
  const { page_config } = props;
  let privacy_link = _.get(page_config, "footer.privacy_policy_link");
  let terms_link = _.get(page_config, "footer.terms_condition_link")
  return (
    <Nav vertical>
      <NavItem>
        <NavLink href={privacy_link}>
          <span>Privacy Policy</span>
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink href={terms_link}>
          <span>Terms and Conditions</span>
        </NavLink>
      </NavItem>
    </Nav>

  )
}

const mapStateToProps = (state) => {
  return {
    page_config: _.get(state, "my_profile.page_config", {})
  }
}

export default connect(mapStateToProps)(LegalDrawer)