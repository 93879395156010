import "es5-shim";
import "es6-shim";
import "react-app-polyfill/ie9";
import "raf/polyfill";
import "url-search-params-polyfill";

import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ConnectedRouter } from "connected-react-router";

import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";

//import "./assets/crediwatch.scss";
import configureStore, { history } from "./config/configureStore";

const store = configureStore();


const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
      <ToastContainer />
        <Component />
      </ConnectedRouter>
    </Provider>,
    rootElement
  );
};

renderApp(App);

if (module.hot) {
  module.hot.accept("./App", () => {
    const NextApp = require("./App").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
