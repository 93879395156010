import React from "react";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import "react-responsive-tabs/styles.css";
import { connect } from "react-redux";
import _ from 'lodash';
import FeatureList from "./tabComponents/FeatureList";

function OptionDropdown(props) {
  // function getTabs() {
  //   let apps = _.get(props, "my_org.apps", {});
  //   let app_arr = _.isObject(apps) ? Object.keys(apps) : [];
  //   let tabArr = [];
  //   tabArr.push(
  //     {
  //       title: "Features",
  //       getContent: () => <FeatureList />,
  //     }
  //   )
  // if(_.isArray(app_arr) && app_arr.length > 1) {
  //   tabArr.push(
  //     {
  //       title: "Modules",
  //       getContent: () => <ProductList apps={apps}/>,
  //     }
  //   )
  // }
  //   return tabArr
  // }
  return (
    <div className="pl-1 pr-1">
      <UncontrolledDropdown>
        <DropdownToggle className="p-0 m-0" color="link">
          <img src={`${process.env.REACT_APP_CDN_URL}/assets/icons/product/feature_icon.svg`} alt="" />
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-xl rm-pointers" >
          <FeatureList />
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    my_org: _.get(state, "my_profile.my_org")
  };
}

export default connect(mapStateToProps)(OptionDropdown) 
