import _ from 'lodash'
export default (type) => {
  let bool = false;
  if(_.isString(type) && type === "b2sme") {
    bool = true
  }
  return bool
}

const check_is_enterprise = (type) => {
  let bool = false;
  if(_.isString(type) && type === "enterprise") {
    bool = true
  }
  return bool
}

export {
  check_is_enterprise
}