export const LOAD_PARTY_INFO = "LOAD_PARTY_INFO";
export const SET_PARTY_INFO = "SET_PARTY_INFO";
export const ERROR_PARTY_INFO = "ERROR_PARTY_INFO";

export const LOAD_ADMIN_PARTY_INFO = "LOAD_ADMIN_PARTY_INFO";
export const SET_ADMIN_PARTY_INFO = "SET_ADMIN_PARTY_INFO";
export const ERROR_ADMIN_PARTY_INFO = "ERROR_ADMIN_PARTY_INFO";


export const LOAD_BUSINESS_ACTIVITY = "LOAD_BUSINESS_ACTIVITY";
export const SET_BUSINESS_ACTIVITY = "SET_BUSINESS_ACTIVITY";

export const LOAD_EWS_SCORES_OLD_DATA = "LOAD_EWS_SCORES_OLD_DATA";
export const SET_EWS_SCORES_OLD_DATA = "SET_EWS_SCORES_OLD_DATA";

export const LOAD_EWS_SCORES_NEW_DATA = "LOAD_EWS_SCORES_NEW_DATA";
export const SET_EWS_SCORES_NEW_DATA = "SET_EWS_SCORES_NEW_DATA";

export const LOAD_EWS_RATIOS = "LOAD_EWS_RATIOS";
export const SET_EWS_RATIOS = "SET_EWS_RATIOS";

export const LOAD_EWS_STOCK_DATA = "LOAD_EWS_STOCK_DATA";
export const SET_EWS_STOCK_DATA = "SET_EWS_STOCK_DATA";

export const LOAD_EWS_MAPPING_SCORES_DATA = "LOAD_EWS_MAPPING_SCORES_DATA";
export const SET_EWS_MAPPING_SCORES_DATA = "SET_EWS_MAPPING_SCORES_DATA";

export const LOAD_STAND_ALONE_FINANCIAL = "LOAD_STAND_ALONE_FINANCIAL";
export const SET_STAND_ALONE_FINANCIAL = "SET_STAND_ALONE_FINANCIAL";

export const LOAD_CONSOLIDATE_FINANCIAL = "LOAD_CONSOLIDATE_FINANCIAL";
export const SET_CONSOLIDATE_FINANCIAL = "SET_CONSOLIDATE_FINANCIAL";

export const LOAD_RATIOS_FINANCIAL = "LOAD_RATIOS_FINANCIAL";
export const SET_RATIOS_FINANCIAL = "SET_RATIOS_FINANCIAL";

export const LOAD_CONSOLIDATE_RATIOS_FINANCIAL =
  "LOAD_CONSOLIDATE_RATIOS_FINANCIAL";
export const SET_CONSOLIDATE_RATIOS_FINANCIAL =
  "SET_CONSOLIDATE_RATIOS_FINANCIAL";

export const LOAD_QUARTERLY_FINANCIAL = "LOAD_QUARTERLY_FINANCIAL";
export const SET_QUARTERLY_FINANCIAL = "SET_QUARTERLY_FINANCIAL";

export const LOAD_CONSOLIDATE_QUARTERLY_FINANCIAL =
  "LOAD_CONSOLIDATE_QUARTERLY_FINANCIAL";
export const SET_CONSOLIDATE_QUARTERLY_FINANCIAL =
  "SET_CONSOLIDATE_QUARTERLY_FINANCIAL";

export const LOAD_SANCTION_DETAILS = "LOAD_SANCTION_DETAILS";
export const SET_SANCTION_DETAILS = "SET_SANCTION_DETAILS";

export const LOAD_NEWS_API = "LOAD_NEWS_API";
export const SET_NEWS_API = "SET_NEWS_API";

export const LOAD_PARTY_CLAIM_INFO = "LOAD_PARTY_CLAIM_INFO";
export const SET_PARTY_CLAIM_INFO = "SET_PARTY_CLAIM_INFO";

export const LOAD_REGISTRATIONS = "LOAD_REGISTRATIONS";
export const SET_REGISTRATIONS = "SET_REGISTRATIONS";
