export const SET_PROFILE = 'SET_PROFILE';
export const LOAD_PROFILE='LOAD_PROFILE';
export const ERROR_PROFILE='ERROR_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const LOAD_REPORT_TYPES = 'LOAD_REPORT_TYPES';
export const SET_REPORT_TYPES = 'SET_REPORT_TYPES';
export const LOAD_REPORTS = 'LOAD_REPORTS';
export const SET_REPORTS = 'SET_REPORTS'
export const SET_ORG = 'SET_ORG';

export const SET_WATCH_LIST_INFO = 'SET_WATCH_LIST_INFO'

export const LOAD_MCA_HISTORY = "LOAD_MCA_HISTORY";
export const SET_MCA_HISTORY = "SET_MCA_HISTORY";

export const LOAD_EQARO_SETUP = "LOAD_EQARO_SETUP"
export const SAVE_EQARO_SETUP = "SAVE_EQARO_SETUP"
export const SET_PAGE_CONFIG = "SET_PAGE_CONFIG"