import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import _ from 'lodash';

function HubSpot(props) {
  const { region, portalId, formId } = props;
  let profileData = _.get(props, "profileData", {})
  let name = _.get(profileData, 'name', '');
  let email = _.get(profileData, "email", '');
  let mobile_number = _.get(profileData, "mobile_number", "")
  useEffect(() => {
    if (window.hbspt) {
      // @TS-ignore
      window.hbspt.forms.create({
        region: region,
        portalId: portalId,
        formId: formId,
        target: '#hubspotForm',
        onFormReady: function($form) {
          if(name) {
            $form.find('input[name="firstname"]').val(name).change();
          }
          if(email) {
            $form.find('input[name="email"]').val(email).change();
          }
          if(mobile_number) {
            $form.find('input[name="phone"]').val(mobile_number).change(); 
          }
        }
      })
    }
  }, []);

  return (
    <div>
      <div id="hubspotForm"></div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  profileData: _.get(state, "my_profile.profileData")
})

export default connect(mapStateToProps)(HubSpot)
