import moment from 'moment';
export default function daysAgoDateFormat(lastLoginTime) {

  if (lastLoginTime.isValid()) {
    const currentTime = moment();
    const daysAgo = currentTime.diff(lastLoginTime, 'days');
    const hoursAgo = currentTime.diff(lastLoginTime, 'hours');
    let lastLoginMessage;
    if (daysAgo === 0) {
      if (hoursAgo >= 1) {
        if (hoursAgo < 24) {
          lastLoginMessage = `${hoursAgo} ${hoursAgo === 1 ? 'hour' : 'hours'} ago`;
        } else {
          lastLoginMessage = 'Today';
        }
      } else {
        lastLoginMessage = 'Just now';
      }
    } else if (daysAgo === 1) {
      lastLoginMessage = "Yesterday";
    } else {
      lastLoginMessage = `${daysAgo} days ago`;
    }
    return (lastLoginMessage);
  }
  return "";
}
