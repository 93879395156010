import React, { useState, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchBox from "../../../../../layout/AppHeader/Components/SearchBox";
import _ from "lodash";
import allPaths from "../../../../../route/allPaths";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

function OrderReportFromDashboard(props) {
  const [selectedPartyDetails, setSelectedPartyDetails] = useState({});
  const [report, setReport] = useState("");

  const PopData = [
    {
      id: "c-360",
      reportname: "C-360 report",
      logo: "https://cdn.crediwatch.com/assets/images/c-360-icon.svg",
      subTitle: "Quick view of a business",
    },
    {
      id: "c-docs",
      reportname: "C-Docs report",
      logo: "https://cdn.crediwatch.com/assets/images/c-docs-icon.svg",
      subTitle: "MCA Documents",
    },
    {
      id: "c-trust",
      reportname: "C-Trust report",
      logo: "https://cdn.crediwatch.com/assets/images/c-trust-icon.svg",
      subTitle: "In-depth understanding of a business entity",
    },
  ];

  const OrderReport = (party) => {
    setSelectedPartyDetails(party);
  };

  const removeParty = () => {
    setSelectedPartyDetails(" ");
    setReport(" ");
  };

  const redirectCheckout = () => {
    if (party_id && report) {
      const { history } = props;
      let checkoutURL = `${allPaths.PARTY_ROOT_ROUTE}/${party_id}/checkout?slug=${report}`;
      history.push(checkoutURL);
    }

  };

  // const directToPartyPage = () => {
  //   if (party_id !== undefined) {
  //     const { history } = props;
  //     let partyURL = `${allPaths.PARTY_ROOT_ROUTE}/${party_id}`;
  //     history.push(partyURL);
  //   }
  // };

  const selectReport = (reports_type) => {

    if (party_id) {
      setReport(reports_type);
    }
  };



  const party_name = selectedPartyDetails.name;
  const party_id = selectedPartyDetails.party_id;
  const party_type = selectedPartyDetails.type;
  const mobileView = props.mobileView ? props.mobileView : false;
  const orderReportView =
    <div>
      <div className="d-flex justify-content-between">
        <h6 className="text-secondary fw-500">To Order Search & Select a Business</h6>
      </div>
      <div className="mb-2">
        <SearchBox enableInMobile={true} showConfirmBox={OrderReport} displaySearch={true} />
      </div>
      <div>
        {party_name ? (
          <Fragment>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-2">
                <Button
                  className="mb-2 mr-2 btn-pill order-card-popup-bg-color text-secondary"
                  active
                  color="primary"
                >
                  <a href={`${allPaths.PARTY_ROOT_ROUTE}/${party_id}`} target="_blank">{party_name}</a>
                  <span className="cursor-p ml-2" onClick={removeParty}>
                    <i className="fa fa-times" aria-hidden="true"></i>
                  </span>
                </Button>
              </div>

            </div>
          </Fragment>
        ) : (
          " "
        )}
      </div>
      <h6 className="text-secondary mt-4">Report Options</h6>
      <div className="container-fluid">
        {party_type !== "person" ? (
          <div className="row">
            {PopData.map((eachData, index) => {

              return (
                <div
                  className={`col-md-4 ${party_name ? "cursor-p" : "disabled-cursor"
                    } `}
                  onClick={() => selectReport(eachData.id)}
                  key={index}
                >
                  <div
                    className={
                      report === eachData.id
                        ? `order-card-popup-bg-color border border-primary rounded p-2 h-100`
                        : `order-card-popup-bg-color rounded p-2 h-100`
                    }
                  >
                    <img
                      src={eachData.logo}
                      width={35}
                      alt={eachData.reportname}
                    />
                    <h6 className="mt-1 mb-1 fw-500 text-black text-wrap">
                      {eachData.reportname}
                    </h6>
                    <div className="text-wrap">{eachData.subTitle}</div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="col-4">
            <div className="order-card-popup-bg-color p-2 rounded disabled-cursor">
              <img
                src="https://cdn.crediwatch.com/assets/images/c-360-icon.svg"
                width={35}
                alt="error messaage"
              />
              <h6 className="mt-1 mb-1 fw-500 text-black text-wrap">Sorry!</h6>
              <div className="text-wrap">
                You cannot order report for this category of business.
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="text-right mt-3">
        <button
          className={
            report !== "" ? "cw-btn cw-btn-primary " : "cw-btn cw-btn-primary disabled"
          }
          onClick={redirectCheckout}
        >
          Order Report
        </button>
      </div>
    </div>;
  return (
    <Fragment>
      {!mobileView ?
        <Modal toggle={props.close} className="" isOpen={props.show}>
          <ModalHeader className="text-primary fw-600" toggle={props.close}>Order Report</ModalHeader>
          <ModalBody>
            {orderReportView}
          </ModalBody>
        </Modal>
        :
        <Fragment>{orderReportView}</Fragment>
      }
    </Fragment>
  );
}
function mapStateToProps(state) {
  return {
    reports_types: _.get(state.my_profile, "my_org.sku"),
    policy: _.get(state.my_profile, "profileData.policy"),
  };
}

export default connect(
  mapStateToProps,
  null
)(withRouter(OrderReportFromDashboard));
