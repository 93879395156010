export const LOAD_LIST_OF_PORTFOLIO = "LOAD_LIST_OF_PORTFOLIO";
export const LIST_OF_PORTFOLIO = "LIST_OF_PORTFOLIO";

export const SAVE_CREATE_PORTFOLIO = "SAVE_CREATE_PORTFOLIO";
export const UPDATE_LIST_PORTFOLIO = "UPDATE_LIST_PORTFOLIO";

export const LOAD_PORTFOLIO = "LOAD_PORTFOLIO";
export const SET_PORTFOLIO = "SET_PORTFOLIO";

export const LOAD_PORTFOLIO_PARTIES = "LOAD_PORTFOLIO_PARTIES";
export const SET_PORTFOLIO_PARTIES = "SET_PORTFOLIO_PARTIES";

export const ADD_PARTY_DATA = "ADD_PARTY_DATA";
export const UPDATE_ALERT_TABLE_FOR_CASE="UPDATE_ALERT_TABLE_FOR_CASE";

export const SAVE_LIST_OF_PARTY_NAME = "SAVE_LIST_OF_PARTY_NAME";

export const LOAD_ALERT_LIST_ACCOUNT = "LOAD_ALERT_LIST_ACCOUNT";
export const SET_ALERT_LIST_ACCOUNT = "SET_ALERT_LIST_ACCOUNT";

export const LOAD_ALERT_CASE_TYPES_DATA = "LOAD_ALERT_CASE_TYPES_DATA";
export const SET_ALERT_CASE_TYPES_DATA = "SET_ALERT_CASE_TYPES_DATA";

export const LOAD_PORTFOLIO_ALERTS_DATA = "LOAD_PORTFOLIO_ALERTS_DATA";
export const SET_PORTFOLIO_ALERTS_DATA = "SET_PORTFOLIO_ALERTS_DATA";

export const SET_ALERT_FILTER_DROPDOWN="SET_ALERT_FILTER_DROPDOWN";

export const LOAD_MACRO_INDICATORS="LOAD_MACRO_INDICATORS";
export const SET_MACRO_INDICATORS="SET_MACRO_INDICATORS";

export const ENABLE_PORTFOLIO_SEARCHBAR = "ENABLE_PORTFOLIO_SEARCHBAR";