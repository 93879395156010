import React, { Fragment } from "react";
import _ from "lodash";
import moment from "moment";
import blueUserIcon from "./blue-user-icon1.sv.svg";
import {
  DropdownToggle,
  DropdownMenu,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";

import { Mixpanel } from "../../../utils/mixpanel";
import { removeToken } from "../../../utils/getToken";
import detect_mobile from "../../../utils/detect_mobile";
import MyAccount from "../../../components/MyAccount";
import daysAgoDateFormat from "../../../utils/x_days_ago_date";

class UserBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      org_pic: {
        status: "not-triggered",
        path: "",
      },
      dropdownOpen: false
    };
  }

  closeDropdown = () => {
    this.setState({
      dropdownOpen: false,
    });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  getDefaultOrgImg = () => {
    const { page_config } = this.props;
    let show_default_cw_logo = _.get(
      page_config,
      "header.show_default_cw_logo",
      false
    );
    return show_default_cw_logo
      ? `${process.env.REACT_APP_CDN_URL}/assets/images/Your_logo_here.svg`
      : "";
  };

  loadImageURL = async (path, def_img) => {
    try {
      let res = await axios.get(path);
      let dupObj = {
        status: "loading",
        path: "",
      };
      if (res && res.status === 200) {
        dupObj.path = path;
      } else {
        dupObj.path = def_img;
      }
      dupObj.status = "done";
      this.setState({
        org_pic: dupObj,
      });
    } catch (e) {
      this.setState({
        org_pic: {
          status: "done",
          path: def_img,
        },
      });
    }
  };

  componentDidMount() {
    let logo_url = _.get(this.props.my_org, "logo_url");
    let def_org = this.getDefaultOrgImg();
    if (logo_url && this.isValidHttpUrl(logo_url)) {
      this.loadImageURL(logo_url, def_org);
    } else {
      this.setState({
        org_pic: {
          status: "done",
          path: def_org,
        },
      });
    }
  }

  icon = () => {
    return <i className="far fa-user"></i>;
  };

  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (e) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  }

  profilePic = () => {
    let profile_pic = _.get(this.props.my_profile, "profile_pic");
    if (profile_pic && this.isValidHttpUrl(profile_pic)) {
      return profile_pic;
    } else {
      return blueUserIcon;
    }
  };

  logOut = () => {
    let login_TYPE = process.env.REACT_APP_LOGIN_TYPE;

    let origin = _.get(window, "location.origin");

    if (login_TYPE === "amplify") {
      Mixpanel.reset();
      removeToken();
      window.location.href = `${process.env.REACT_APP_AUTH_URL}/logout`;
    } else if (login_TYPE === "cognito") {
      let base_uri = process.env.REACT_APP_AUTH_URL;
      let client_id = process.env.REACT_APP_CLIENT_ID;
      let redirect_uri = `${origin}/auth`;
      let response_type = "code";
      if (base_uri && client_id) {
        removeToken();
        window.location.href = `${base_uri}/logout?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}`;
      }
    }
  };

  render() {
    const { org_pic } = this.state;
    let name = _.get(this.props.my_profile, "name");
    let company = _.get(this.props.my_profile, "organization.name");
    let updatedAt = _.get(this.props.my_profile, "updated_at");
    let copyright_year = new Date().getFullYear();
    let isMobile = detect_mobile()

    return (
      <Fragment>
        <div className=" mb-2 mr-2 mt-2  p-1">
          <div className="widget-content p-0 mr-1 ml-1">
            <div className="widget-content-wrapper border rounded p-1">
              <UncontrolledButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                <DropdownToggle color="" className="p-0">
                  <div className="d-flex">
                    <div className="mr-2 d-none d-xl-block d-lg-block d-md-block">
                      {org_pic.status === "done" && org_pic.path && (
                        <img src={org_pic.path} width={78} height={32} />
                      )}
                    </div>
                    <img
                      width={35}
                      className="rounded-circle"
                      src={this.profilePic()}
                      alt=""
                    />
                  </div>
                </DropdownToggle>
                <DropdownMenu className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right">
                  <div className="dropdown-menu-headern bg-white pl-3 pt-3 pr-3">
                    <div className="dropdown-menu-header-inner ">
                      <div className="menu-header-image opacity-2"></div>
                      <div className="menu-header-content text-left">
                        <div className="widget-content p-0">
                          <div className="widget-content-wrapper">
                            <div className="widget-content-left mr-3">
                              <img
                                width="35"
                                className="rounded-circle"
                                src={this.profilePic()}
                                alt=""
                              />
                            </div>
                            <div className="widget-content-left">
                              <div className="widget-heading font-weight-bold pr-2 pb-2">
                                {name}
                              </div>
                              <div className="widget-subheading text-dark">
                                {_.capitalize(company)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul className="nav flex-column">
                    <li className="nav-item-divider nav-item"></li>
                    <li className="nav-item-btn text-left nav-item" onClick={this.closeDropdown}>
                      <MyAccount />
                    </li>
                    <li className="nav-item-divider nav-item"></li>
                  </ul>
                  <div className="grid-menu grid-menu-2col">
                    <div className="no-gutters row">
                      <div className="col-sm-7">
                        <div className="border-right pr-2 h-100">
                          <button
                            type="button"
                            className="btn-pill btn-shadow btn-shine btn btn-focus"
                            onClick={(e) => this.logOut(e)}
                          >
                            Logout
                          </button>
                          <div className="widget-subheading fs-12 my-2 d-flex flex-column">
                            <div>Last login{": "}
                            <span>{daysAgoDateFormat(moment(updatedAt))}</span>
                            </div>
                            <div className="fs-10">({moment(updatedAt).format("DD/MM/YYYY HH:mm")})</div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-5 fs-12 text-justify">
                        <div className="fs-14 mb-1">Legal</div>
                        <div>
                          <a href="https://about.crediwatch.com/legal/privacy-policy/">
                            Privacy policy
                          </a>
                        </div>
                        <div>
                          <a href="https://about.crediwatch.com/legal/cookie-policy/">
                            Cookie Policy
                          </a>
                        </div>
                        <div>
                          <a href="https://about.crediwatch.com/legal/terms-and-conditions/">
                            API Terms of Use
                          </a>
                        </div>
                        {isMobile ? (<div className="  p-0">
                          {`©${copyright_year} | All Rights Reserved.`}
                        </div>) : ""}
                      </div>
                    </div>
                  </div>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    my_profile: _.get(state, "my_profile.profileData"),
    my_org: _.get(state, "my_profile.my_org"),
    page_config: _.get(state, "my_profile.page_config", {}),
  };
}

export default connect(mapStateToProps, null)(UserBox);
