import React from "react";
import { useDispatch } from "react-redux";
import { setEnableClosedSidebar } from "../../reducers/ThemeOptions";
import { connect } from "react-redux";
import { Slider } from "react-burgers";
import _ from "lodash";

const EnableSlider = (props) => {
  let dispatch = useDispatch();

  const toggleEnableClosedSidebar = () => {
    dispatch(setEnableClosedSidebar(!props.enableClosedSidebar));
  };
  let enableSideBar =
  _.isArray(props.sideBarItems) && props.sideBarItems.length > 0 ? true : false;
  return (
    <div
      data-tut="party-sidebar-responsive"
      onClick={toggleEnableClosedSidebar}
      className="d-none d-md-block"
    >
      {enableSideBar&&
      <Slider
        width={26}
        lineHeight={2}
        lineSpacing={5}
        color="#4F5D7B"
        active={!props.enableClosedSidebar}
        onClick={toggleEnableClosedSidebar}
      />

}
    </div>
  );
};

const mapStateToProps = (state) => ({
  enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
  sideBarItems: state.ThemeOptions.sideBarItems,
});
export default connect(mapStateToProps, null)(EnableSlider);
