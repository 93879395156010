import _ from 'lodash';

import jwt_decode from "jwt-decode";
import allPaths from "../route/allPaths";

const getToken = () => {
  const token =
    localStorage && localStorage.getItem("CW_JWT_TOKEN") // NOSONAR
      ? localStorage.getItem("CW_JWT_TOKEN") // NOSONAR
      : false;

  if(token) {
    if(isValidToken(token)) {
      return token;
    }
  }
  return false;
}

const isValidToken = (token) => {
  let decoded = {}
  try {
    decoded = jwt_decode(token);
  } catch(e) {
    decoded = {}
  }
  let expire_time = _.get(decoded, "exp", 0);
  let current_time = Math.floor(new Date()/1000);
  if(expire_time>=current_time) {
    return true
  } else {
    return false
  }
}

const setToken = (token) => {
  localStorage && localStorage.setItem("CW_JWT_TOKEN", token)
}

const removeToken = () => {
  localStorage && localStorage.removeItem("CW_JWT_TOKEN")
}

const addRedirectURL = () => {
  let url = `${window.location.pathname}${window.location.search}`
  if(!window.location.pathname.includes(allPaths.SET_TOKEN_ROUTE)) {
    localStorage && localStorage.setItem('redirect_url', url)
  }
}

const getRedirectURL = () => {
  let url = localStorage && localStorage.getItem('redirect_url')
    ? localStorage && localStorage.getItem('redirect_url')
    : false;
  return url;
}

const removeRedirectURL = () => {
  localStorage && localStorage.removeItem('redirect_url')
}

export {
  getToken,
  setToken,
  removeToken,
  addRedirectURL,
  getRedirectURL,
  removeRedirectURL
}