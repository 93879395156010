import React from 'react'
import {useHistory} from "react-router-dom"
import allPaths from '../../route/allPaths';
const MyAccount = () => {
const history = useHistory();

  const navigateToAccountDetails=()=>{
    history.push(allPaths.MY_ACCOUNT);
  }
  
  return (
    <button className='btn btn-outline-primary btn-transition-primary mb-2 pb-2' onClick={navigateToAccountDetails}>My Account</button>
  )
}

export default MyAccount