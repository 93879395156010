import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import _ from "lodash";

import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import CustomLink from "./CustomLink";

class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      NavList: [],
    };
  }

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  onSelection = (params) => {
    const { history } = this.props;
    history.push(params.url);
    this.toggleMobileSidebar();
  };

  getIcon = (icon) => {
    let iconList = [
      "highlights",
      "basic-info",
      "alerts",
      "registrations",
      "ews",
      "notification",
      "relationships",
      "financials",
      "charges",
      "legal",
      "timelines",
      "sanctions",
      "news",
      "contact",
      "documents",
      "ratings",
      "iec",
      "blacklists",
      "trade-check",
      "gstn-info",
      "epf-check",
      "media",
      "epfo",
      "icon-reports",
      "business-details",
      "cases",
    ];
    return iconList.includes(icon) ? `icon icon-${icon}` : `${icon}`;
  };

  usersRoute = (path) => {
    const { history } = this.props;
    history.push(path.url);
  };

  converterFunc = () => {
    const { sideBarItems } = this.props;
    let arr = _.chain(sideBarItems)
      .map((menu, menuKey) => {
        return {
          ...menu,
          onSelection: this.onSelection,
          icon: this.getIcon(menu.icon),
        };
      })
      .value();
    return arr;
  };

  convertToEnterpriseSideBar = () => {
    const { enterpriseSideBarData } = this.props;
    let arr = _.chain(enterpriseSideBarData)
      .map((menu, menuKey) => {
        return {
          ...menu,
          onSelection: this.usersRoute,
          icon: menu.icon,
        };
      })
      .value();
    return arr;
  };

  convertToAminSideBar = () => {
    const { adminSideBarData } = this.props;
    let arr = _.chain(adminSideBarData)
      .map((menu, menuKey) => {
        return {
          ...menu,
          onSelection: this.usersRoute,
          icon: menu.icon,
        };
      })
      .value();
    return arr;
  };

  is_widget_disabled = (url) => {
    let current_policy_method = _.get(this.props, `policy.${url}.method`, []);
    if (
      _.isArray(current_policy_method) &&
      current_policy_method.includes("GET")
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const nav_list = this.converterFunc();
    const shouldRenderDiv = this.props.sideBarItems.some(
      (item) => item.label === "Parties" || item.label === "Porfolios"
    );
    const updatedSideBarItems = this.props.sideBarItems.map((item) => {
      const url = item.url;
      const disabled = this.is_widget_disabled(url);
      return { ...item, disabled };
    });
    const first5SideBarItems = updatedSideBarItems
      .slice(0, 5)
      .filter((item) => !item.disabled);
    const remainingSideBarItems = updatedSideBarItems
      .slice(5)
      .filter((item) => !item.disabled);
    return (
      <Fragment>
        {shouldRenderDiv ? (
          <>
            {first5SideBarItems.length > 0 && (
              <>
                <h5 className="app-sidebar__heading">Features</h5>
                <MetisMenu
                  content={first5SideBarItems}
                  onSelected={this.onSelection}
                  activeLinkFromLocation
                  LinkComponent={CustomLink}
                  className="vertical-nav-menu"
                  iconNamePrefix=""
                  classNameStateIcon="pe-7s-angle-down"
                />
                <h5 className="app-sidebar__heading">Administration</h5>
                <MetisMenu
                  content={remainingSideBarItems}
                  onSelected={this.onSelection}
                  activeLinkFromLocation
                  LinkComponent={CustomLink}
                  className="vertical-nav-menu"
                  iconNamePrefix=""
                  classNameStateIcon="pe-7s-angle-down"
                />
              </>
            )}
          </>
        ) : (
          <>
            <h5 className="app-sidebar__heading">Menu</h5>
            <MetisMenu
              content={nav_list}
              onSelected={this.onSelection}
              activeLinkFromLocation
              LinkComponent={CustomLink}
              className="vertical-nav-menu"
              iconNamePrefix=""
              classNameStateIcon="pe-7s-angle-down"
            />
          </>
        )}
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
const mapStateToProps = (state) => {
  return {
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    sideBarItems: state.ThemeOptions.sideBarItems,
    policy: _.get(state, "my_profile.profileData.policy"),
    loader: _.get(state, "party.claim_info.status"),
    isClaimed: _.get(state, "party.claim_info.result.data.claimed"),
    party_id: _.get(state, "party.registration.result.attributes.party_id"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
