import * as actionType from "../../actions/mybiz/actionTypes";

let initialState = {
  mybizdata: {
    status: "not-triggered",
    result: null,
  },
  tally_info: {
    status: 'not-triggered',
    result: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionType.LOAD_MY_BIZ_API:
      return {
        ...state,
        mybizdata: {
          status: "loading",
          result: null,
        },
      };
    case actionType.SET_MY_BIZ_API:
      return {
        ...state,
        mybizdata: {
          status: "done",
          result: action.payload,
        },
      };

    case actionType.LOAD_TALLY_INFO_API:
      return {
        ...state,
        tally_info: {
          status: 'loading',
          result: null
        },
      }
    case actionType.SAVE_TALLY_INFO:
      return {
        ...state,
        tally_info: {
          status: 'done',
          result: action.payload
        }
      }
    default:
  }
  return state;
}
