import React, { Fragment } from "react";
import cx from "classnames";
import { connect } from "react-redux";
import _ from 'lodash';
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { withRouter } from "react-router-dom";

import HeaderLogo from "../AppLogo";
import MegaMenu from "../../components/MegaMenu";
import allPaths from "../../route/allPaths";

class PublicHeader extends React.Component {

  get_header_cdn_path = (file_name) => {
    return `${process.env.REACT_APP_CDN_URL}/assets/icons/header/${file_name}`
  }

  get_contact_mega_menu = () => {
    let data = {
      id: 'company',
      title: 'Company',
      icon: this.get_header_cdn_path('contact-us.svg')
    }

    const list = [
      {
        title: 'About ',
        description: 'Want to know more about Crediwatch? Here’s everything you need to know.',
        details: [
          {
            label: 'About Us',
            url: `https://about.crediwatch.com/about/about-us/`,
            description: 'Discover how Crediwatch enables the financial inclusion of small businesses by reducing credit and trading risks.'
          },
          {
            label: 'Become a Channel Partner',
            url: `https://about.crediwatch.com/about/channel-partner/`,
            description: 'Our products and solutions can help you in more ways than one. See what becoming a partner can unlock.'
          },
          {
            label: 'News',
            url: `https://about.crediwatch.com/about/news/`,
            description: 'Wondering what’s latest with Crediwatch? Find out here.'
          },
          {
            label: 'Careers',
            url: `https://about.crediwatch.com/about/careers/`,
            description: 'Are you a strong believer in financial inclusion and digital trust? Join us and let’s make it possible.'
          },
          {
            label: 'CEAP',
            url: `https://about.crediwatch.com/about/crediwatch-early-adopters-program/`,
            description: 'See how becoming a part of Crediwatch Early Adopters Program can benefit you.'
          },
          {
            label: 'Contact Us',
            url: `https://about.crediwatch.com/contact/contact-us/`,
            description: 'Drop in a line or connect with our representatatives now.'
          },
        ]
      }
    ]
    return (
      <MegaMenu data={data} list={list} />
    )
  }


  get_product_mega_menu = () => {
    let data = {
      id: 'product',
      title: 'Products',
      icon: this.get_header_cdn_path('product.svg')
    }

    const list = [
      {
        title: 'Products',
        description: 'See how our AI/ML powered products can give you an edge with timely and actionable intelligence.',
        details: [
          {
            label: 'Early Warning Systems',
            url: `https://about.crediwatch.com/product/early-warning-systems/`,
            description: 'Provide credit risk and collection teams potential distress signals up to 12 months in advance.'
          },
          {
            label: 'Enhanced Credit Reports',
            url: `https://about.crediwatch.com/product/enhanced-credit-reports/`,
            description: 'Get an all-encompassing, one-of-its-kind credit report based on 45+ parameters.'
          },
          {
            label: 'Fin Tracker',
            url: `https://about.crediwatch.com/product/fintracker/`,
            description: 'Cross-analyze financial data from multiple sources to arrive at 3x deeper insights. '
          },,
          {
            label: 'UBO Analysis',
            url: `https://about.crediwatch.com/product/ubo-analysis/`,
            description: 'Get an accurate representation of the ultimate beneficial owner(s) of any business.'
          },
          {
            label: 'Trust Score',
            url: `https://about.crediwatch.com/product/trustscore/`,
            description: 'Get multi-dimensional view of even thin-file customers, suppliers, and competitors.'
          },
          {
            label: 'Quick Reports',
            url: `https://about.crediwatch.com/product/quick-reports/`,
            description: 'Understand a business, gauge creditworthiness and get in-depth information in a few clicks.'
          },
          {
            label: 'Case Management',
            url: `https://about.crediwatch.com/product/early-warning-systems/case-management/`,
            description: 'Organize, prioritize, manage, and track the progress of each risk case with ease.'
          }
        ]
      }
    ]
    return (
      <MegaMenu data={data} list={list} />
    )
  }

  get_resource_mega_menu = () => {
    let data = {
      id: 'resource',
      title: 'Resources',
      icon: this.get_header_cdn_path('resource.svg')
    }

    const list = [
      {
        title: 'Resources',
        details: [
          {
            label: 'Blogs',
            url: `https://about.crediwatch.com/resources/blogs/`
          },
          {
            label: 'Insights',
            url:`https://about.crediwatch.com/resources/insights/`
          },
          {
            label: 'Case Studies',
            url:`https://about.crediwatch.com/resources/casestudies/`
          },
          {
            label: 'FAQs',
            url:`https://about.crediwatch.com/resources/faq/`
          },
          {
            label: 'White Papers',
            url:`https://about.crediwatch.com/resources/whitepapers/`
          },
        ]
      }
    ]
    return (
      <MegaMenu data={data} list={list} />
    )
  }

  get_solutions_mega_menu = () => {
    let data = {
      id: 'solutions',
      title: 'Solutions',
      icon: this.get_header_cdn_path('solution.svg')
    }

    const list = [
      {
        title: 'For Enterprise',
        description: ' Enhance credit life cycle management and decision-making with AI-powered tools.',
        button: {
          text: 'know more',
          url: `https://about.crediwatch.com/solutions/enterprise/`
        },
        details: [
          {
            label: 'Financial Institutions',
            url: `https://about.crediwatch.com/solutions/enterprise/financial-institutions/`,
            description: 'Better understand your borrowers, minimize NPAs and miximize lending opportunities.'
          },
          {
            label: 'Embedded Finance',
            url: `https://about.crediwatch.com/solutions/enterprise/embedded-finance-api/`,
            description: 'Use Embedded Finance APIs and configurable workflows to reduce risk and enhance experiences.'
          },
          {
            label: 'Professional Services',
            url: `https://about.crediwatch.com/solutions/enterprise/professional-firms/`,
            description: 'Use a digital trust platform to know your potential clients and accelerate due diligence.'
          }
        ]
      },
      {
        title: 'For Businesses',
        description: 'From law firms to MSMEs, see how Crediwatch products can benefit all business owners.',
        details: [
          {
            label: 'Small businesss',
            url: `https://about.crediwatch.com/solutions/business/msme/`,
            description: 'With coverage of 10 million+ businesses, get access to intelligence that matter to you.'
          },
          {
            label: 'Verify Covid Suppliers',
            url: `https://about.crediwatch.com/solutions/business/verify-covid-resources/`,
            description: 'Deal with only legitimate COVID resource suppliers with these quick verification tools.'
          },
          {
            label: 'Law Firms',
            url: `https://about.crediwatch.com/solutions/business/law-firms/`,
            description: 'From monitoring legal disputes to searching for regulatory filings, simplify it all.'
          }
        ]
      },
    ]
    return (
      <MegaMenu data={data} list={list} />
    )
  }

  redirectToLogin = () => {
    const { history } = this.props
    history.push(allPaths.HOME_ROUTE)
  }

  render() {
    let {
      headerBackgroundColor,
      enableHeaderShadow,
    } = this.props;
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          className={cx("app-header", headerBackgroundColor, {
            "header-shadow": enableHeaderShadow,
          })}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          
          <HeaderLogo />
          {this.get_solutions_mega_menu()}
          {this.get_product_mega_menu()}
          {this.get_resource_mega_menu()}
          {this.get_contact_mega_menu()}
          <div className="app-header__content ">
            <div className="app-header-right d-flex align-items-center">
              <a href="https://about.crediwatch.com/contact/contact-us/" className="cw-btn cw-btn-primary mr-3">
                Talk to us
              </a>
              <button className="cw-btn cw-btn-secondary" onClick={this.redirectToLogin}>
                Login
              </button>
            </div>
          </div>
          <button className="ml-2 btn btn-link d-block d-md-none" onClick={()=>window.open(`https://about.crediwatch.com/contact/contact-us/`,"_self")}>
          <img src={this.get_header_cdn_path("contact-us.svg")} className="icon-svg"/>
          </button>
         
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  page_config: _.get(state, "my_profile.page_config", {})
});

export default connect(mapStateToProps, null)(withRouter(PublicHeader));
