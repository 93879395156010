import React, { Fragment } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Slider } from "react-burgers";
import { withRouter } from "react-router-dom";

import {
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";

class AppMobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
    };
  }

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  toggleMobileSmall = () => {
    let { enableMobileMenuSmall, setEnableMobileMenuSmall } = this.props;
    setEnableMobileMenuSmall(!enableMobileMenuSmall);
  };

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  };

  render() {
    const { sideBarItems, enableMobileMenu,location} = this.props;
    const {pathname} = location
    const userPaths = ["/admin/users","/admin/jobs","/admin/roles"]
    let userSidebar = userPaths.filter(each => pathname.includes(each))
    let enableSideBar =
      _.isArray(sideBarItems) && sideBarItems.length > 0 ? true : false;
    return (
      <Fragment>
       <div className="app-header__mobile-menu p-0">
          {enableSideBar && (
            <div
              data-tut="party-sidebar-responsive"
              onClick={this.toggleMobileSidebar}
            >
              <Slider
                width={26}
                lineHeight={2}
                lineSpacing={5}
                color="#4F5D7B"
                active={enableMobileMenu}
                onClick={this.toggleMobileSidebar}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  sideBarItems: state.ThemeOptions.sideBarItems,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setEnableMobileMenuSmall: (enable) =>
    dispatch(setEnableMobileMenuSmall(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppMobileMenu));
