import _ from "lodash";

import * as myprofileActionType from "../../actions/myprofile/actionTypes";
import check_is_b2sme, {
  check_is_enterprise,
} from "../../utils/check_is_b2sme";

let initialState = {
  profileData: {},
  profile_status: "not-triggered",
  report_types: {
    status: "not-triggered",
    result: null,
  },
  reports: {
    status: "not-triggered",
    result: null,
  },
  my_org: {},
  my_watclist: {
    status: "not-triggered",
    result: {},
  },
  userData: {
    status: "not-triggered",
    result: {},
  },
  mcaRefresh:{
    status: "not-triggered",
    result: {},
  },
  eqaro_step_info: {
    status: 'not-triggered',
    result: ""
  },
  page_config: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case myprofileActionType.SET_PAGE_CONFIG:
      return {
        ...state,
        page_config: action.payload
      }
    case myprofileActionType.SET_WATCH_LIST_INFO:
      return {
        ...state,
        my_watclist: {
          status: "done",
          result: action.payload,
        },
      };
    case myprofileActionType.LOAD_PROFILE:
      return {
        ...state,
        profile_status: "loading",
      };
    case myprofileActionType.ERROR_PROFILE:
      return {
        ...state,
        profile_status: "error",
      };

    case myprofileActionType.SET_ORG:
     
      return {
        ...state,
        my_org: {
          ...action.payload,
          is_b2sme: check_is_b2sme(_.get(action.payload, "type")),
          is_enterprise: check_is_enterprise(_.get(action.payload, "type")),
        },
      };

    case myprofileActionType.SET_PROFILE:
      return {
        ...state,
        profileData: action.profile,
        profile_status: "done",
      };

    case myprofileActionType.LOAD_REPORT_TYPES:
      return {
        ...state,
        report_types: {
          status: "loading",
          result: null,
        },
      };
    case myprofileActionType.SET_REPORT_TYPES:
      return {
        ...state,
        report_types: {
          status: "done",
          result: action.payload,
        },
      };

    case myprofileActionType.LOAD_REPORT_TYPES:
      return {
        ...state,
        reports: {
          status: "loading",
          result: null,
        },
      };

    case myprofileActionType.SET_REPORTS:
      return {
        ...state,
        reports: {
          status: "done",
          result: action.payload,
        },
      };
    case myprofileActionType.UPDATE_PROFILE:
      let currentProfile = _.isObject(state.profileData) ? {...state.profileData} : {};
      let industries = _.get(action, "payload.data.industries", []);
      let interests = _.get(action, "payload.data.interests", []);
      let wt = _.get(action, "payload.data.wt", {});
      let user_provided_info = _.get(action,"payload.data.user_provided_info")
      let instance_name = _.get(action, "payload.data.instance_name", "");
      if(industries) {
        currentProfile.industries = industries;
      }
      if(interests) {
        currentProfile.interests = interests;
      }
      if(wt) {
        currentProfile.wt = wt
        currentProfile.loaded_wt = true
      }
      if(user_provided_info) {
        currentProfile.user_provided_info = user_provided_info;
      }
      if(instance_name) {
        currentProfile.instance_name = instance_name
      }
      return {
        ...state,
        profileData: currentProfile
      };

    case myprofileActionType.LOAD_EQARO_SETUP:
      return {
        ...state,
        eqaro_step_info: {
          status: "loading",
          result: {},
        }
      }

    case myprofileActionType.SAVE_EQARO_SETUP:
      return {
        ...state,
        eqaro_step_info: {
          status: "done",
          result: action.payload,
        }
      }
    case myprofileActionType.LOAD_MCA_HISTORY:
      return {
        ...state,
        mcaRefresh: {
          status: "loading",
          result: null,
        },
      };

    case myprofileActionType.SET_MCA_HISTORY:
      return {
        ...state,
        mcaRefresh: {
          status: "done",
          result: action.payload,
        },
      };
      
    default:
  }
  return state;
}
