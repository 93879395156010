import React,  { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import allPaths from './allPaths';
const EqaroHandler = (props) => {
  const history = useHistory();
  const { profile_instance, eqaro_step_info, path } = props;

  const eqaro_step_info_status = _.get(eqaro_step_info, "result");
  

  useEffect(() => {
    if (profile_instance && eqaro_step_info_status) {
      check_eqaro_flow();
    }
    return () => {}
  }, [profile_instance, eqaro_step_info_status, path])


  const verifyEqaroStepflow = () => {
    let bool_check = false;
    let allowed_steps = ['step-4', 'step-5', 'eligible', 'ineligible']
    if (eqaro_step_info.status === 'done') {
      if (allowed_steps.includes(eqaro_step_info_status)) {
        return true
      }
    }
    return bool_check
  }

  const check_eqaro_flow = () => {
    if (profile_instance === 'eqaro') {
      if (eqaro_step_info.status === 'done') {
        let is_eqaro_flow_done = verifyEqaroStepflow();
        if (!is_eqaro_flow_done) {
          history.push(allPaths.EQARO_STEP_SETUP_ROUTE)
        }
      }
    }
  }

  return <></>
}

function mapStateToProps(state) {
  return {
    profile_instance: _.get(state, "my_profile.profileData.instance_name"),
    eqaro_step_info: _.get(state, "my_profile.eqaro_step_info")
  }
}

export default connect(mapStateToProps)(EqaroHandler);