import _ from "lodash";
import allPaths from "../route/allPaths";

const redirectLoginSSO = () => {
  let login_TYPE = process.env.REACT_APP_LOGIN_TYPE;

  let origin = _.get(window, 'location.origin');

  if(login_TYPE === 'amplify') {
    let currentLocation = `${process.env.REACT_APP_AUTH_URL}?app_uri=${process.env.REACT_APP_REDIRECT_BASE_URL}${allPaths.SET_TOKEN_ROUTE}`;
    window.location.href = currentLocation
  } else if(login_TYPE === 'cognito') {
    let base_uri = process.env.REACT_APP_AUTH_URL;
    let client_id = process.env.REACT_APP_CLIENT_ID;
    let redirect_uri = `${process.env.REACT_APP_REDIRECT_BASE_URL}/auth`;
    let response_type = 'code';
    if (base_uri && client_id) {
      window.location.href = `${base_uri}/login?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}`;
    } else {
      console.log("Please Check  ENV")
    } 
  }
};

export default redirectLoginSSO;