import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";

import ThemeOptions from "../reducers/ThemeOptions";
import my_profile from "../reducers/my_profile/reducer";
import party from "../reducers/party/reducer";
import portfolio from "../reducers/portfolio/reducer";
import dashboard from "../reducers/dashboard/reducer";
import mybiz from "../reducers/mybiz/reducer";
import globalReducer from "../reducers/globalReducer/reducer";

const history = createBrowserHistory();

export default function configureStore() {
  return createStore(
    combineReducers({
      router: connectRouter(history),
      ThemeOptions,
      my_profile,
      party,
      portfolio,
      dashboard,
      mybiz,
      globalReducer
    }),
    applyMiddleware(thunk)
  );
}

export { history };
