import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import {Mixpanel} from '../../../utils/mixpanel'

function Support(props) {
  const openHelp = () => {
    window.FreshworksWidget("open", 'ticketForm');
    let profileData = _.get(props, "profileData", {})
    window.FreshworksWidget("identify", "ticketForm", {
      name: _.get(profileData, 'name', ''),
      email: _.get(profileData, "email", ''),
      customFields: {
        cf_url: _.get(window, "location.href", ""),
        cf_account: _.get(profileData, "organization.name", "")
      }
    });

    Mixpanel.track("visits_support_form", {"page_name": "Support Page"})

  }
  return (
    <div>
      <div className="dropdown mb-2 ml-2 mr-2 mt-2 h5 d-xl-block d-l-block cursor-pointer">
        <img src={`${process.env.REACT_APP_CDN_URL}/assets/icons/product/support.svg`} alt="" onClick={openHelp}/>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  profileData: _.get(state, "my_profile.profileData")
})

export default connect(mapStateToProps)(Support)
