export default Object.freeze({
  LANDING_ROUTE: "/",
  HOME_ROUTE: "/dashboard",
  AUTH_ROUTE: "/auth",
  SET_TOKEN_ROUTE: "/set_token",
  SEARCH_ROUTE: "/search",
  PARTY_ROOT_ROUTE: "/party",
  PORTFOLIOS_LIST_ROUTE: "/portfolios",
  PORTFOLIO_DETAIL_ROOT_ROUTE: "/portfolio",
  PORTFOLIO_ROUTE: "/portfolio/:portfolio_id",
  PORTFOLIO_SUB_ROUTE: "/portfolio/:portfolio_id/:section",
  PARTY_ROUTE: "/party/:party_id",
  PARTY_SUB_ROUTE: "/party/:party_id/:section",
  PRODUCTS_ROUTE: "/account/products",
  PRODUCT_DETAILS_ROUTE: "/account/products/:id/",
  REPORTS_ROUTE: "/account/reports",
  MY_PARTIES_ROUTE: "/myparties",
  PDF_ROUTE: "/pdf",
  MACRO_INDICATOR: "/macro-indicators",
  MY_BIZ_ROUTE: "/my-business",
  MY_BIZ_SUB_ROUTE: "/my-business/:section",
  WATCHLIST: "/watchlist",
  LEGAL_BLOB_ROOT_ROUTE: "/legal",
  LEGAL_BLOB_ROUTE: "/legal/:case_id",
  VERIFY_BUSINESS: "/verify-business",
  ERROR_ROUTE: "/error",
  MCA_STATUS:"/mca-status",
  MCA_SUB_ROUTE:"/mca-status/index",
  MCA_HISTORY_ROUTE:"/mca-status/history/:section",
  MCA_HISTORY_ROOT_ROUTE:"/mca-status/history",
  CONSENT_ROUTE:"/consent",
  CONSENT_SUB_ROUTE:"/consent/:section",
  EQARO_STEP_SETUP_ROUTE:"/basic-setup",
  PUBLIC_HOME_ROUTE: "/home",
  RUBEE_BOSS_SETUP_ROUTE: "/rupeeboss-setup",
  QUICK_CALCULATOR_APP: "/quick-eligibility-calculator",
  CMM_ROUTE:'/cases',
  CASE_DETAILS_MANAGEMENT_ROUTE:"/cases/:case_id",
  ONBOARDING:"/setup-profile",
  MY_ROUTE: "/my",
  MY_PARTIES_ROUTE: "/my/parties",
  MY_ALERTS_ROUTE: "/my/alerts",
  MY_CMM_ROUTE: "/my/cases",
  MY_ALERT:"/alerts",
  MY_JOBS:"/account/jobs",
  DOCS_ROUTE: "/docs",
  DOCS_SECTION: "/docs/:section",
  // SIGNAL_ROOT_ROUTE: "/signals",
  // SCORE_ROOT_ROUTE:"/scores",
  // API_ROOT_ROUTE:"/api",
  // PRODUCTS_ROOT_ROUTE:"/products",
  SIGNALS_SECTION:"/docs/signals/:section",
  PRODUCTS_SECTION:"/docs/products/:section",
  SCORES_SECTION:"/docs/scores/:section",
  PARTIES:"/parties",
  MY_ACCOUNT:"/account/my-profile",
  ACCOUNT_EDIT:"/account/edit",
  ADMIN_USER:"/admin/users",
  USERS_ROUTE:"/admin/users",
  USERS_DETAILS_ROUTE:"/admin/users/:section",
  ORG_UNITS:"/admin/org-units",
  ADMIN_APPROVALS:"/admin/approvals",
  ADMIN_JOBS:"/admin/jobs",
  ADMIN_JOBS_ROOT_ROUTE:"/admin/job",
  ADMIN_JOB_ROUTE:"/admin/job/:job_id",
  ADMIN_UPLOADS:"/uploads",
  ZOHO:"/zoho",
  APPS:"/apps",
  BULK_EXPORT:"/bulk-export"
});
