import React, { useState } from "react";
import {
  Popover,
  Nav,
  NavLink,
  Row,
  NavItem,
} from "reactstrap";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from 'lodash';

import './style.css';

const MegaMenu = (props) => {
  const { data, list } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [popoverOpen, setPopovenOpen] = useState(false);

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
    setPopovenOpen(!popoverOpen)
  }

  const redirect_func = (item) => {
    if (item && item.url) {
      if (item.target) {
        window.open(item.url,"_self");
      }else {
        window.open(item.url,"_self");
      }
    } 
  }

  const getPopupUI = () => {
    let ui_arr = [];
    if (list && _.isArray(list)) {
      list.forEach((item, key) => {
        let details = _.get(item, "details", []);
        ui_arr.push(
          <Row className="no-gutters border-bottom" key={key}>
            <div className="col-12 col-md-4 ">
              <div className="bg-section  h-100 ">
                <div className="h-100 p-3 d-flex align-items-center align-items-center justify-content-center flex-column">
                  <h2 className="mb-20 fs-30 ff-renner-book">{item.title}</h2>
                  {item.description &&
                    <p className="mb-20 text-center ff-renner-book">
                      {item.description}
                    </p>
                  }
                  {item.button &&
                    <button className="cw-btn cw-btn-primary ff-renner-book" onClick={() => redirect_func(item.button)}>
                      {_.get(item, "button.know_more", "know more")}
                    </button>
                  }
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8 ">
              <div className="right-card-p">
                <div className="row">
                  {_.isArray(details) && details.map((a, index) => (
                    <div className="col-md-6 col-12 mega-menu-item"
                      key={index}
                      onClick={() => redirect_func(a)}
                    >
                      <div className="pd-10">
                      <div className={`h2-element ${a.description && 'mb-20'} ff-renner-book`}>{a.label}</div>
                        {a.description &&
                          <p className="h2-sub-element ff-renner-book">
                            {a.description}
                          </p>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Row>
        )
      })
    }
    return ui_arr
  }
  return (
    <Nav className="header-megamenu d-none d-md-block ff-renner-book">
      <NavItem>
        <NavLink href="#" onClick={toggle} id={data.id}>
          {data.icon && <img src={data.icon} alt={data.title} className="header-icon mr-2"/>}
          {data.title}
          <FontAwesomeIcon className="ml-2 opacity-5" icon={faAngleDown} />
        </NavLink>
      </NavItem>
      <Popover className="rm-max-width custom-mega-menu" placement="bottom" fade={false} trigger="legacy"
        isOpen={popoverOpen} target={data.id} toggle={toggle}>
        <div className="dropdown-mega-menu w-100">
          <div className="grid-menu grid-menu-3col">
            {getPopupUI()}
          </div>
        </div>
      </Popover>
    </Nav>
  )
}

export default MegaMenu;
