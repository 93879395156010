import * as portfolioActionType from "../../actions/portfolio/actionTypes";

let initialState = {
  portfolioList: {
    status: "not-triggered",
    result: [],
  },
  createPortfolio: {
    status: "not-triggered",
    result: [],
  },
  portfolio_Details: {
    status: "not-triggered",
    result: [],
  },
  portfolio_parties: {
    status: "not-triggered",
    result: [],
  },
  portfolio_alerts: {
    status: "not-triggered",
    result: [],
  },
  partyList: {
    status: "not-triggered",
    result: [],
  },
  listOfAccount: {
    status: "not-triggered",
    result: [],
  },
  caseTypesData: {
    status: "not-triggered",
    result: [],
  },
  alerts_api_data: {
    status: "not-triggered",
    result: [],
  },
  macro_indicator: {
    status: "not-triggered",
    result: [],
  },
  portfolio_searchbar: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case portfolioActionType.LOAD_LIST_OF_PORTFOLIO:
      return {
        ...state,
        portfolioList: {
          status: "loading",
          result: null,
        },
      };

    case portfolioActionType.LIST_OF_PORTFOLIO:
      return {
        ...state,
        portfolioList: {
          status: "done",
          result: action.payload,
        },
      };

    case portfolioActionType.SAVE_CREATE_PORTFOLIO:
      return {
        ...state,
        portfolioList: {
          status: "done",
          result: action.payload,
        },
      };

    case portfolioActionType.UPDATE_LIST_PORTFOLIO:
      return {
        ...state,
      };

    case portfolioActionType.LOAD_PORTFOLIO:
      return {
        ...state,
        portfolio_Details: {
          status: "loading",
          result: null,
        },
      };

    case portfolioActionType.SET_PORTFOLIO:
      return {
        ...state,
        portfolio_Details: {
          status: "done",
          result: action.payload,
        },
      };

    case portfolioActionType.LOAD_PORTFOLIO_PARTIES:
      return {
        ...state,
        portfolio_parties: {
          status: "loading",
          result: null,
        },
      };
    case portfolioActionType.SET_PORTFOLIO_PARTIES:
      return {
        ...state,
        portfolio_parties: {
          status: "done",
          result: action.payload,
        },
      };
    case portfolioActionType.LOAD_PORTFOLIO_ALERTS_DATA:
      return {
        ...state,
        portfolio_alerts: {
          status: "loading",
          result: null,
        },
      };
    case portfolioActionType.SET_PORTFOLIO_ALERTS_DATA:
      return {
        ...state,
        portfolio_alerts: {
          status: "done",
          result: action.payload,
        },
      };
    case portfolioActionType.SAVE_LIST_OF_PARTY_NAME:
      return {
        ...state,
        partyList: {
          status: "done",
          result: action.payload,
        },
      };
    case portfolioActionType.LOAD_ALERT_LIST_ACCOUNT:
      return {
        ...state,
        listOfAccount: {
          status: "loading",
          result: null,
        },
      };

    case portfolioActionType.SET_ALERT_LIST_ACCOUNT:
      return {
        ...state,
        listOfAccount: {
          status: "done",
          result: action.payload,
        },
      };

    case portfolioActionType.LOAD_ALERT_CASE_TYPES_DATA:
      return {
        ...state,
        caseTypesData: {
          status: "loading",
          result: null,
        },
      };

    case portfolioActionType.SET_ALERT_CASE_TYPES_DATA:
      return {
        ...state,
        caseTypesData: {
          status: "done",
          result: action.payload,
        },
      };

    case portfolioActionType.UPDATE_ALERT_TABLE_FOR_CASE:
      let table =
        state && state.data && state.data.table ? [...state.data.table] : [];
      let getIndex = table.findIndex((a) => a._id === action.alertId);
      if (getIndex > -1) {
        table[getIndex].case_id = action.caseId;
      }
      return {
        ...state,
        data: {
          ...state.data,
          table: table,
        },
      };

    case portfolioActionType.SET_ALERT_FILTER_DROPDOWN:
      return {
        ...state,
        alerts_api_data: {
          status: "done",
          result: action.payload,
        },
      };

    case portfolioActionType.LOAD_MACRO_INDICATORS:
      return {
        ...state,
        macro_indicator: {
          status: "loading",
          result: null,
        },
      };
    case portfolioActionType.SET_MACRO_INDICATORS:
      return {
        ...state,
        macro_indicator: {
          status: "done",
          result: action.payload,
        },
      };

    case portfolioActionType.ENABLE_PORTFOLIO_SEARCHBAR: 
      return {
        ...state,
        portfolio_searchbar: action.payload, 
      };

    default:
  }

  return state;
}
