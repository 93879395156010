import * as actionTypes from "../../actions/globalAction/actionTypes";
import _ from "lodash";
let initialState = {
  searchbar:false
};
const reducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENABLE_SEARCHBAR: 
      return {
        ...state,
        searchbar: action.payload, 
      };  
    default:
      return {
        ...state,
      };
  }  
}
export default reducers;