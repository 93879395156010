import React, { Fragment } from "react";
import cx from "classnames";
import OptionDropdown from "./Components/OptionDropdown";
import { connect } from "react-redux";
import _ from 'lodash';

import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

import HeaderLogo from "../AppLogo";

import { setEnableSearchBarOnHeader } from "../../reducers/ThemeOptions";
import SearchBox from "./Components/SearchBox";
import Support from "./Components/Support";
// import MegaMenu from "./Components/MegaMenu";
import UserBox from "./Components/UserBox";
// import HeaderRightDrawer from "./Components/HeaderRightDrawer";
class Header extends React.Component {
  render() {
    let {
      headerBackgroundColor,
      enableHeaderShadow,
      openInput,
      setEnableSearchBarOnHeader,
      page_config
    } = this.props;
    let show_search_bar = _.get(page_config, "header.show_search_bar", false);
    let show_support = _.get(page_config, "header.show_support", false);
    let show_feature_icon = _.get(page_config, "header.show_feature_icon", false);

    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          className={cx("app-header", headerBackgroundColor, {
            "header-shadow": enableHeaderShadow,
          })}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
        >
          {!openInput && <HeaderLogo />}
          <div id="cwi_header_bar__search" className="flex-grow-1">
            {show_search_bar && <SearchBox openInput={openInput} setEnableSearchBarOnHeader={setEnableSearchBarOnHeader} />}
          </div>
          <div>
            {show_support && !openInput && <Support />}
          </div>
          {!openInput && (
            <div className="app-header-right d-flex align-items-center">
              {show_feature_icon &&
                <div className="d-none d-lg-block">
                  <OptionDropdown />
                </div>
              }
              <UserBox log_out={this.log_out} />
            </div>
          )}
        </CSSTransitionGroup>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  page_config: _.get(state, "my_profile.page_config", {})
});

const mapDispatchToProps = (dispatch) => {
  return {
    setEnableSearchBarOnHeader: (value) =>
      dispatch(setEnableSearchBarOnHeader(value)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
