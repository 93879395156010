import mixpanel from "mixpanel-browser";

// mixpanel.init(`${process.env.MIXPANEL}`, { debug: true });
//mixpanel.init("d0c8c677a4298d2ffce7798b416aef19", { debug: true });
if (process.env.REACT_APP_MIXPANEL) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL, { debug: true, ignore_dnt: true });
}

//mixpanel.init("d0c8c677a4298d2ffce7798b416aef19")

let actions = {
  identify: (id) => {
    if (process.env.REACT_APP_MIXPANEL) {
      mixpanel && mixpanel.identify && mixpanel.identify(id);
    }
  },
  alias: (id) => {
    if (process.env.REACT_APP_MIXPANEL) {
      mixpanel && mixpanel.alias && mixpanel.alias(id);
    }
  },
  track: (name, props) => {
    if (process.env.REACT_APP_MIXPANEL) {
      mixpanel && mixpanel.track && mixpanel.track(name, props);
    }
  },
  setUser: (props) => {
      if (process.env.REACT_APP_MIXPANEL) {
        mixpanel && mixpanel.people && mixpanel.people.set(props);
    }
  },
  register: (name, props) => {
    if (process.env.REACT_APP_MIXPANEL) {
      mixpanel && mixpanel.register && mixpanel.register(name, props);
    }
  },
  reset: () => {
    if (process.env.REACT_APP_MIXPANEL) {
      mixpanel && mixpanel.reset && mixpanel.reset();
    }
  }
};

export let Mixpanel = actions;
