import _ from "lodash";
import { Mixpanel } from ".././utils/mixpanel";
import { removeToken } from ".././utils/getToken";

const logout = () => {
  let login_TYPE = process.env.REACT_APP_LOGIN_TYPE;
  let origin = _.get(window, "location.origin");
  localStorage.setItem('savedPath', window.location.pathname);
  if (login_TYPE === "amplify") {
    Mixpanel.reset();
    removeToken();
    window.location.href = `${process.env.REACT_APP_AUTH_URL}/logout`;
  } else if (login_TYPE === "cognito") {
    let base_uri = process.env.REACT_APP_AUTH_URL;
    let client_id = process.env.REACT_APP_CLIENT_ID;
    let redirect_uri = `${origin}/auth`;
    let response_type = "code";
    if (base_uri && client_id) {
      removeToken();
      window.location.href = `${base_uri}/logout?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=${response_type}`;
    }
  }
};
export default logout;
