import _ from "lodash";
import axios from "axios";

import service from "../../utils/service";
import { removeToken, addRedirectURL } from "../../utils/getToken";
import allPaths from "../../route/allPaths";
import * as myprofileActionType from "./actionTypes";
import { Mixpanel } from "../../utils/mixpanel";
import obj_to_string from '../../helpers/obj_to_string';

export function setProfile(profile) {
  return {
    type: myprofileActionType.SET_PROFILE,
    profile,
  };
}

// const setUserData = (payload) => {
//   return {
//     type: myprofileActionType.SET_USERS_DATA,
//     payload,
//   };
// };

const save_eqaro_step_info = (payload) => {
  return {
    type: myprofileActionType.SAVE_EQARO_SETUP,
    payload
  }
}

const get_eqaro_step_status = (current_step_id, current_step_status) => {
  let step_status = '';
  switch(current_step_id) {
    case 1:
      if(current_step_status === 'done'){
        step_status = 'step-2'
      } else {
        step_status = 'step-1'
      }
      break;
    case 2:
      if(current_step_status === 'done'){
        step_status = 'step-3'
      } else if(current_step_status === 'ineligible') {
        step_status = 'ineligible'
      } else {
        step_status = 'step-2'
      }
      break;
    case 3:
      if(current_step_status === 'done'){
        step_status = 'step-4'
      } else {
        step_status = 'step-3'
      }
      break;
    case 4:
      if(current_step_status === 'done'){
        step_status = 'step-5'
      } else if(current_step_status === 'ineligible') {
        step_status = 'ineligible'
      } else {
        step_status = 'step-4'
      }
      break;
    case 5:
      if(current_step_status === 'done'){
        step_status = 'eligible'
      } else if(current_step_status === 'ineligible') {
        step_status = 'ineligible'
      } else {
        step_status = 'step-5'
      }
      break;
    default:
      step_status = 'step-1'
  }
  return step_status;
}

export const trigger_current_eqaro_step = () => {
  return (dispatch) => {
    dispatch(({type: myprofileActionType.LOAD_EQARO_SETUP }))
    service('get', "/v1/user/current_step").then((res) => {
      let current_step_id = _.get(res, "data.current_step_id");
      let current_step_status =_.get(res, "data.current_step_status");
      let step_status = get_eqaro_step_status(current_step_id, current_step_status)
      if(res.data) {
        dispatch(save_eqaro_step_info(step_status))
      }
    })
  }
}

const setUserData = (payload) => {
  return {
    type: myprofileActionType.UPDATE_PROFILE,
    payload,
  };
};

export const userApi = (user_id) => {
  return (dispatch) => {
    service("get", `/v1/admin/users/${user_id}`).then((res) => {
       dispatch(setUserData(res));
        let instance_name = _.get(res, "data.instance_name", "");
        if(instance_name === "eqaro") {
          dispatch(trigger_current_eqaro_step())
        }
    });
  };
};

export function setOrg(org_data) {
  return {
    type: myprofileActionType.SET_ORG,
    payload: org_data,
  };
}

const getMyProfileApi = () => {
  return service("get", "/v1/account/myprofile");
};

const getMyOrgApi = () => {
  return service("get", "/v1/account/myorg");
};

const getWatchList = () => {
  let params = {
    page: 1,
    per_page: 10,
    sort_by: "created_at",
    sort_in: "asc",
    list_type: "watchlist",
  };
  return service("get", "/v1/admin/lists", {
    params,
  });
};

const createWatchList = () => {
  let data = {
    list: {
      name: "Default",
      description: "Default",
      list_type: "watchlist",
    },
  };
  return service("post", "/v1/admin/lists", {
    data,
  });
};

const get_page_config_json = () => {
  return async (dispatch) => {
    let page_config_key = process.env.REACT_APP_PAGE_CONFIG || 'crediwatch';
    let URL = `${process.env.REACT_APP_CDN_URL}/assets/page_config/${page_config_key}.json`
    let page_json = await axios.get(URL);
    let data = _.get(page_json, "data", {})
    dispatch({ type: myprofileActionType.SET_PAGE_CONFIG, payload: data })
  }
}

export const load_page_config_json = () => {
  return (dispatch) => {
    dispatch(get_page_config_json())
  }
}

export function getMyprofile() {  
  return (dispatch) => {
    dispatch({ type: myprofileActionType.LOAD_PROFILE });
    axios.all([getMyProfileApi(), getMyOrgApi(), getWatchList()]).then(
      axios.spread(function (profile_data, org_data, watchlist_data) {
        dispatch(get_page_config_json())
        let mixPanelObj = {
          user_id: "",
          organization_id: "",
          organization_name: "",
          username: "",
          email: "",
          user_type: "",
          access_url: "",
        };
        dispatch(setOrg(org_data));
        mixPanelObj.organization_id = _.get(org_data, "organization_id");
        mixPanelObj.user_type = _.get(org_data, "type");

        if (_.get(profile_data, "data.user_id")) {
          dispatch(userApi(_.get(profile_data, "data.user_id")));
          dispatch(setProfile(profile_data.data));
          mixPanelObj.user_id = _.get(profile_data, "data.user_id");
          mixPanelObj.username = _.get(profile_data, "data.name");
          mixPanelObj.organization_name = _.get(
            profile_data,
            "data.organization.name"
          );
          mixPanelObj.access_url = window.location.origin;
          mixPanelObj.email = _.get(profile_data, "data.email");
          Mixpanel.identify(_.get(profile_data, "data.email"));
          Mixpanel.setUser({
            ...mixPanelObj,
            $email: _.get(profile_data, "data.email"),
            $name: _.get(profile_data, "data.name"),
          });
          Mixpanel.register(mixPanelObj);
        } else {
          if (_.get(profile_data, "status") === 403) {
            removeToken();
            addRedirectURL();
            window.location.replace(allPaths.ERROR_ROUTE);
          } else {
            dispatch({ type: myprofileActionType.ERROR_PROFILE });
          }
        }

        /**Watchlist */
        let w_data = _.get(watchlist_data, "data", []);
        if (w_data.length > 0) {
          dispatch(setWatchListInfo(w_data[0]));
        } else {
          axios.all([createWatchList()]).then(
            axios.spread(function (create_list_data) {
              axios.all([getWatchList()]).then(
                axios.spread(function (new_data) {
                  let new_w_data = _.get(new_data, "data", []);
                  if (new_w_data.length > 0) {
                    dispatch(setWatchListInfo(new_w_data[0]));
                  }
                })
              );
            })
          );
        }

      })
    );
  };
}

const setWatchListInfo = (data) => {
  return {
    type: myprofileActionType.SET_WATCH_LIST_INFO,
    payload: data,
  };
};

export function loadReportTypes() {
  return {
    type: myprofileActionType.LOAD_REPORT_TYPES,
  };
}

export function setReportTypes(data) {
  return {
    type: myprofileActionType.SET_REPORT_TYPES,
    payload: data,
  };
}

export function loadReports() {
  return {
    type: myprofileActionType.LOAD_REPORTS,
  };
}

export function setReports(reports) {
  return {
    type: myprofileActionType.SET_REPORTS,
    payload: reports,
  };
}

export function getReports(params) {
  return (dispatch) => {
    dispatch(loadReports());
    let multi_value_obj = {
      product_definition_type: ['report', 'timeline']
    }
    let str_params = obj_to_string(params, multi_value_obj)
    service("get", `/v1/admin/products?${str_params}`)
      .then(function (response) {
        dispatch(setReports(response));
      })
      .catch(function (error) { });
  };
}

export function loadMcaHistory(){
  return {
    type: myprofileActionType.LOAD_MCA_HISTORY
  }
}

export function setMcaHistory(data){
  return{
    type: myprofileActionType.SET_MCA_HISTORY,
    payload: data
  }
}

export function getMcaRefreshData(params){
  let defaultParams={
    page: 1,
    per_page: 5,
    sort_by: "created_at",
    sort_in: "desc",
    type: 'single'
  }
  return (dispatch)=>{
    dispatch(loadMcaHistory());
    service("get", "/v2/admin/orders",{
      params: params || defaultParams
    }).then(function(response){
      dispatch(setMcaHistory(response))
    })
    .catch(function (error) {
      console.log(error)
    })
  }
}


