import * as partyActionTypes from "../../actions/party/actionTypes";
import _ from "lodash";

let initialState = {
  information: {
    status: "not-triggered",
    result: null,
  },
  admin_party_info:{
    status: "not-triggered",
    result: null,
  },
  business_activity: {
    status: "not-triggered",
    result: null,
  },
  ews: {
    old_scores: {
      status: "not-triggered",
      result: null,
    },
    new_scores: {
      status: "not-triggered",
      result: null,
    },
    mapping_ews_data: {
      status: "not-triggered",
      result: null,
    },
    mapping_stock_data: {
      status: "not-triggered",
      result: null,
    },
    mapping_ratio_data: {
      status: "not-triggered",
      result: null,
    },
  },
  financials_stand_alone: {
    status: "not-triggered",
    result: null,
  },
  financials_consolidated: {
    status: "not-triggered",
    result: null,
  },
  financials_ratios: {
    status: "not-triggered",
    result: null,
  },
  financials_ratios_consolidated: {
    status: "not-triggered",
    result: null,
  },
  financials_quarterly: {
    status: "not-triggered",
    result: null,
  },
  sanctions: {
    status: "not-triggered",
    result: null,
  },
  news: {
    status: "",
    result: [],
  },
  claim_info: {
    status: "not-triggered",
    result: null,
  },
  registrations:{
    status: "not-triggered",
    result: null,
  }
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case partyActionTypes.LOAD_PARTY_INFO:
      return {
        ...state,
        information: {
          status: "loading",
          result: null,
        },
      };
    case partyActionTypes.SET_PARTY_INFO:
      return {
        ...state,
        information: {
          status: "done",
          result: {
            ...action.payload
          },
        },
      };

    case partyActionTypes.ERROR_PARTY_INFO:
      return {
        ...state,
        information: {
          status: "error",
          result: null,
        },
      };

    case partyActionTypes.LOAD_ADMIN_PARTY_INFO:
      return{
        ...state,
        admin_party_info:{
          status: "loading",
          result: null,
        }
      }  
      case partyActionTypes.SET_ADMIN_PARTY_INFO:
        return {
          ...state,
          admin_party_info: {
            status: "done",
            result: {
              ...action.payload
            },
          },
        };
        case partyActionTypes.ERROR_ADMIN_PARTY_INFO:
          return {
            ...state,
            admin_party_info: {
              status: "error",
              result: null,
            },
          };

    case partyActionTypes.LOAD_BUSINESS_ACTIVITY:
      return {
        ...state,
        business_activity: {
          status: "loading",
          result: null,
        },
      };
    case partyActionTypes.SET_BUSINESS_ACTIVITY:
      return {
        ...state,
        business_activity: {
          status: "done",
          result: action.payload,
        },
      };

    case partyActionTypes.LOAD_EWS_SCORES_OLD_DATA:
      return {
        ...state,
        ews: {
          ...state.ews,
          old_scores: {
            status: "loading",
            result: null,
          },
        },
      };
    case partyActionTypes.SET_EWS_SCORES_OLD_DATA:
      return {
        ...state,
        ews: {
          ...state.ews,
          old_scores: {
            status: "done",
            result: action.payload,
          },
        },
      };

    case partyActionTypes.LOAD_EWS_SCORES_NEW_DATA:
      return {
        ...state,
        ews: {
          ...state.ews,
          new_scores: {
            status: "loading",
            result: null,
          },
        },
      };
    case partyActionTypes.SET_EWS_SCORES_NEW_DATA:
      return {
        ...state,
        ews: {
          ...state.ews,
          new_scores: {
            status: "done",
            result: action.payload,
          },
        },
      };

    case partyActionTypes.LOAD_EWS_RATIOS:
      return {
        ...state,
        ews: {
          ...state.ews,
          mapping_ratio_data: {
            status: "loading",
            result: null,
          },
        },
      };
    case partyActionTypes.SET_EWS_RATIOS:
      return {
        ...state,
        ews: {
          ...state.ews,
          mapping_ratio_data: {
            status: "done",
            result: action.payload,
          },
        },
      };

    case partyActionTypes.LOAD_EWS_STOCK_DATA:
      return {
        ...state,
        ews: {
          ...state.ews,
          mapping_stock_data: {
            status: "loading",
            result: null,
          },
        },
      };
    case partyActionTypes.SET_EWS_STOCK_DATA:
      return {
        ...state,
        ews: {
          ...state.ews,
          mapping_stock_data: {
            status: "done",
            result: action.payload,
          },
        },
      };

    case partyActionTypes.LOAD_EWS_MAPPING_SCORES_DATA:
      return {
        ...state,
        ews: {
          ...state.ews,
          mapping_ews_data: {
            status: "loading",
            result: null,
          },
        },
      };
    case partyActionTypes.SET_EWS_MAPPING_SCORES_DATA:
      return {
        ...state,
        ews: {
          ...state.ews,
          mapping_ews_data: {
            status: "done",
            result: action.payload,
          },
        },
      };

    case partyActionTypes.LOAD_SANCTION_DETAILS:
      return {
        ...state,
        sanctions: {
          status: "loading",
          result: null,
        },
      };
    case partyActionTypes.SET_SANCTION_DETAILS:
      return {
        ...state,
        sanctions: {
          status: "done",
          result: action.payload,
        },
      };

    case partyActionTypes.LOAD_STAND_ALONE_FINANCIAL:
      return {
        ...state,
        financials_stand_alone: {
          status: "loading",
          result: null,
        },
      };
    case partyActionTypes.SET_STAND_ALONE_FINANCIAL:
      return {
        ...state,
        financials_stand_alone: {
          status: "done",
          result: action.payload,
        },
      };

    case partyActionTypes.LOAD_CONSOLIDATE_FINANCIAL:
      return {
        ...state,
        financials_consolidated: {
          status: "loading",
          result: null,
        },
      };
    case partyActionTypes.SET_CONSOLIDATE_FINANCIAL:
      return {
        ...state,
        financials_consolidated: {
          status: "done",
          result: action.payload,
        },
      };

    case partyActionTypes.LOAD_RATIOS_FINANCIAL:
      return {
        ...state,
        financials_ratios: {
          status: "loading",
          result: null,
        },
      };
    case partyActionTypes.SET_RATIOS_FINANCIAL:
      return {
        ...state,
        financials_ratios: {
          status: "done",
          result: action.payload,
        },
      };

    case partyActionTypes.LOAD_CONSOLIDATE_RATIOS_FINANCIAL:
      return {
        ...state,
        financials_ratios_consolidated: {
          status: "loading",
          result: null,
        },
      };
    case partyActionTypes.SET_CONSOLIDATE_RATIOS_FINANCIAL:
      return {
        ...state,
        financials_ratios_consolidated: {
          status: "done",
          result: action.payload,
        },
      };

    case partyActionTypes.LOAD_QUARTERLY_FINANCIAL:
      return {
        ...state,
        financials_quarterly: {
          status: "loading",
          result: null,
        },
      };
    case partyActionTypes.SET_QUARTERLY_FINANCIAL:
      return {
        ...state,
        financials_quarterly: {
          status: "done",
          result: action.payload,
        },
      };
    case partyActionTypes.LOAD_CONSOLIDATE_QUARTERLY_FINANCIAL:
      return {
        ...state,
        financials_consolidated_quarterly: {
          status: "loading",
          result: null,
        },
      };
    case partyActionTypes.SET_CONSOLIDATE_QUARTERLY_FINANCIAL:
      return {
        ...state,
        financials_consolidated_quarterly: {
          status: "done",
          result: action.payload,
        },
      };

    case partyActionTypes.LOAD_NEWS_API:
      return {
        ...state,
        news: {
          status: "loading",
          result: null,
        },
      };

    case partyActionTypes.SET_NEWS_API:
      return {
        ...state,
        news: {
          status: "done",
          result: action.payload,
        },
      };

    case partyActionTypes.LOAD_PARTY_CLAIM_INFO:
      return {
        ...state,
        claim_info: {
          status: "loading",
          result: null,
        },
      };
    case partyActionTypes.SET_PARTY_CLAIM_INFO:
      return {
        ...state,
        claim_info: {
          status: "done",
          result: action.payload,
        },
      };
      case partyActionTypes.LOAD_REGISTRATIONS:
      return {
        ...state,
        registration: {
          status: "loading",
          result: null,
        },
      };
      case partyActionTypes.SET_REGISTRATIONS:
      return {
        ...state,
        registration: {
          status: "done",
          result: action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducers;
