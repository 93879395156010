import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import allPaths from '../../route/allPaths';
import { Mixpanel } from '../../utils/mixpanel';

const ErrorPage = (props) => {

  const pushURL = () => {
    const { history } = props;
    history.push(allPaths.HOME_ROUTE)
  }

  useEffect(() => {
    Mixpanel.track("error_occurred", {error_type: "Issue With Login"});
  },[]);

  return (
    <div className='w-100 h-100 d-flex align-items-center justify-content-center justify-content-center'>
      <div className='card p-3'>
        <div className='m-2'>Error on Profile</div>
        <button onClick={pushURL} className="btn btn-outline-primary mt-3">Click to Login</button>
      </div>
    </div>
  )
}

export default withRouter(ErrorPage);
