import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";

class CustomLink extends React.Component {
  constructor() {
    super();
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    this.props.activateMe({
      url: this.props.to,
    });
  }

  getActiveOrNot = () => {
    let pathName = _.get(window, "location.pathname");
    let activeBool = false;
    let url_arr = _.isString(pathName) ? pathName.split('/') : [];

    if (pathName.includes(this.props.to)) {
      activeBool = true;
    } else if(_.isArray(url_arr) && url_arr.length === 2 && this.props.label === 'Highlights') {
      activeBool = true;
    }
    return activeBool;
  };

  render() {
    return (
      <a
        className={`metismenu-link cursor-p ${
          this.getActiveOrNot() && this.props.classNameActive
        }`}
        onClick={this.onClick}
      >
        {this.props.children}
      </a>
    );
  }
}

export default withRouter(CustomLink);